import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../style-utilities/jagVariables';
import LinkedLogo from '../LinkedLogo';
import FooterNav from './FooterNav';
import FooterSocial from './FooterSocial';

const StyledFooter = styled.footer`
  padding-bottom: 35px;
  padding-top: 35px;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
    text-align: initial;

    .linked-logo {
      flex-grow: 1;
    }
  }
`;

const Footer = ({ theme }) => {
  return (
    <StyledFooter className="page-container">
      <span className="linked-logo">
        <LinkedLogo theme={theme} />
      </span>
      <FooterNav theme={theme} />
      <FooterSocial theme={theme} />
    </StyledFooter>
  );
};

export default Footer;
