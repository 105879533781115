export const colors = {
  primary: '#0c121c',
  gray1: '#58616b',
  gray2: '#69727b',
  gray3: '#6d7986',
  gray4: '#7f8b99',
  gray5: '#f5f5f5',
  textGray: 'rgba(255,255,255,.5)',
  inputGray: 'rgba(255,255,255,.7)'
};

export const breakpoints = {
  mobileMax: '767px',
  tablet: '768px',
  desktop: '1200px',
  ultraWideDesktop: '1680px'
};
