import React from 'react';
import lrlogo from '../images/landrover/lr-logo.png';
import jlogo from '../images/jaguar/jag-logo-png@2x.png';
import styled from 'styled-components';

const Modal = ({ theme, campaigns }) => {
  const modalBackgroundStyles = {
    zIndex: '1000',
    backgroundColor: '#000',
    height: '100vh',
    width: '100vw',
    opacity: '0.7',
    position: 'fixed'
  }
  const modalBoxBackgroundStyles = {
    backgroundColor: '#58616b',
    position: 'fixed',
    zIndex: '2000',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '5px 5px 30px #282828'
  }
  const modalBoxBackgroundJaguarStyles = {
    backgroundColor: '#000000',
    position: 'fixed',
    zIndex: '2000',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '5px 5px 30px #282828'
  }
  const textStyles = {
    textAlign: 'center',
    color: 'white',
    width: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
  const h1Styles = {
    textTransform: 'uppercase',
    marginBottom: '0px'
  }
  const h3Styles = {
    marginLeft: '15%',
    marginRight: '15%'
  }
  const aStyles = {
    color: '#00C9FF'
  }
  const h1DivStyles = {
    marginBottom: '30px'
  }

  const StyledModal = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .hidden {
      display: none;
    }
  `;

  const StyledModalBox = styled.div`
    padding: 15px;
    @media (max-width: 637px) {
      .custom-modal h1 {
        margin-left: 0;
        margin-right: 0;
        font-size: 25px;
      }
      .custom-modal h3 {
        font-size: 16px;
      }
      .custom-modal-logo {
        width: 22vw;
      }
    }

    @media (min-width: 768px) {
      max-width: 75vw;
    }
    @media (min-width: 1200px) {
      max-width: 60vw;
    }
    @media (min-width: 1680px) {
      max-width: 50vw;
    }
  `;

  let activeCampaigns = false;
  for (let i = 0; i < campaigns.length; i++) {
    if (campaigns[i].active === true) {
      activeCampaigns = true;
    }
  }

  return (
    <StyledModal className={activeCampaigns ? 'hidden' : ''}>
      <div style={modalBackgroundStyles} />

      {theme === 'landrover' && (
        // <div className='custom-modal-box' style={modalBoxBackgroundStyles}>
        //   <div className="custom-modal" style={textStyles}>
        //     <div>
        //       <img className='custom-modal-logo' src={lrlogo} alt='Land Rover' />
        //     </div>
        //     <div style={h1DivStyles}>
        //       <h1 style={h1Styles}>2019 Land Rover Campaign Closed</h1>
        //       {/* <h1 style={h1Styles}>Coming Soon</h1> */}
        //     </div>
        //     <h3 style={h3Styles}>All campaigns are closed for now. Please come back in August for the Land Rover Tax Advantage campaign.</h3>
        //     <h3 style={h3Styles}>Please contact <a style={aStyles} href="mailto:Robert.Prinster@ansira.com">Robert.Prinster@ansira.com</a> with any questions.</h3>
        //   </div>
        // </div>

        <StyledModalBox style={modalBoxBackgroundStyles}>
          <div className="custom-modal" style={textStyles}>
            <div>
              <img
                className="custom-modal-logo"
                src={lrlogo}
                alt="Land Rover"
              />
            </div>
            <div style={h1DivStyles}>
              <h1 style={h1Styles}>2019 Land Rover Holiday Sales Event</h1>
              {/* <h1 style={h1Styles}>Coming Soon</h1> */}
            </div>
            <h3 style={h3Styles}>
              Please contact our Ansira Field Marketing Managers to place your
              Conquest Email and Direct Mail orders:
            </h3>
            <h3 style={h3Styles}>
              Tom Kneifel <br/>
              <a style={aStyles} href="mailto:Tom.kneifel@ansira.com">Tom.kneifel@ansira.com</a><br/> 
              <a style={aStyles} href="tel:708-548-0094">708-548-0094</a></h3>
            <h3 style={h3Styles}>
              Dominic Jackson <br />
              <a style={aStyles} href="mailto:Dominic.jackson@ansira.com">
                Dominic.jackson@ansira.com
              </a>
              <br />
              <a style={aStyles} href="tel:314-783-2443">
                314-783-2443
              </a>
            </h3>
          </div>
        </StyledModalBox>
      )}

      {theme === 'jaguar' && (
        // <div className='custom-modal-box' style={modalBoxBackgroundJaguarStyles}>
        //   <div className="custom-modal" style={textStyles}>
        //     <div>
        //       <img className='custom-modal-logo' src={jlogo} alt='Jaguar' />
        //     </div>
        //     <div style={h1DivStyles}>
        //       <h1 style={h1Styles}>2019 Jaguar Campaign Closed</h1>
        //     </div>
        //     <h3 style={h3Styles}>All campaigns are closed for now. Please come back in October for the Jaguar Holiday Sales Event.</h3>
        //     <h3 style={h3Styles}>Contact <a style={aStyles} href="mailto:Robert.Prinster@ansira.com">Robert.Prinster@ansira.com</a> with any questions.</h3>
        //   </div>
        // </div>

        <StyledModalBox style={modalBoxBackgroundJaguarStyles}>
          <div className="custom-modal" style={textStyles}>
            <div>
              <img className="custom-modal-logo" src={jlogo} alt="Jaguar" />
            </div>
            <div style={h1DivStyles}>
              <h1 style={h1Styles}>2019 Jaguar Holiday Sales Event</h1>
              {/* <h1 style={h1Styles}>Coming Soon</h1> */}
            </div>
            <h3 style={h3Styles}>
              Please contact our Ansira Field Marketing Managers to place your
              Conquest Email and Direct Mail orders:
            </h3>
            <h3 style={h3Styles}>
              Tom Kneifel <br />
              <a style={aStyles} href="mailto:Tom.kneifel@ansira.com">Tom.kneifel@ansira.com</a><br/> 
              <a style={aStyles} href="tel:708-548-0094">708-548-0094</a></h3>
            <h3 style={h3Styles}>
              Dominic Jackson <br />
              <a style={aStyles} href="mailto:Dominic.jackson@ansira.com">Dominic.jackson@ansira.com</a><br/> 
              <a style={aStyles} href="tel:314-783-2443">314-783-2443</a></h3>
              
          </div>
        </StyledModalBox>
      )}

    </StyledModal>
  );
}

export default Modal;
