import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from '../style-utilities/jagVariables';
import CountsBlock from './CountsBlock/CountsBlock';

const StyledCampaignOrderForm = styled.div`
  padding-top: 50px;

  p {
    color: ${colors.textGray};
  }
`;

const CampaignOrderForm = ({
  campaign,
  selectedDealer,
  orderCounts,
  updateOrderCounts,
  updateOrderTotal,
  handleSecondStep,
  theme
}) => {
  return (
    <StyledCampaignOrderForm>
      <section className="intro-content">
        <h3>
          Select{' '}
          {campaign.type === 'F-TYPE1'
            ? campaign.type.toUpperCase()
            : campaign.title2}{' '}
           Materials
        </h3>
        {campaign.type === 'Holiday Sales Event' && (
          <ul>
            <li>
              <p>1 Direct Mail, 2 Emails</p>
            </li>
            <li>
              <p>$1.59 per piece total</p>
            </li>
          </ul>
        )}
        {campaign.type === 'F-TYPE' && (
          <ul>
            <li>
              <p>1 Direct Mail, 2 Emails</p>
            </li>
            <li>
              <p>$1.59 per piece total</p>
            </li>
          </ul>
        )}
        {/* {campaign.type === 'Approved Certified Pre-Owned Sales Event' && (
          <ul>
            <li>
              <p>1 Direct Mail, 2 Emails</p>
            </li>
            <li>
              <p>$1.59 per piece total</p>
            </li>
          </ul>
        )} */}
        {campaign.type === 'Full Line' && (
          <ul>
            <li>
              <p>1 Direct Mail, 2 Emails</p>
            </li>
            <li>
              <p>$1.74 per piece total</p>
            </li>
          </ul>
        )}
        {campaign.type === 'Land Rover Discovery Campaign' && (
          <ul>
            <li>
              <p>1 Direct Mail, 2 Emails</p>
            </li>
            <li>
              <p>$1.59 per piece total</p>
            </li>
          </ul>
        )}
        {campaign.type === 'I-PACE' && (
          <ul>
            <li>
              <p>1 Direct Mail, 2 Emails</p>
            </li>
            <li>
              <p>$1.36 per piece total</p>
            </li>
          </ul>
        )}
        {campaign.type === 'Tax Advantage' && (
          <ul>
            <li>
              <p><strong>Option 1:</strong></p>
              <p>1 Direct Mail, 2 Emails</p>
              <p>$1.36 per piece total</p>
            </li>
            <li>
            <p><strong>Option 2:</strong></p>
              <p> 1 Direct Mail, 1 Postcard, 2 Emails</p>
              <p>$1.91 per piece total</p>
            </li>
          </ul>
        )}
        <p>
          Choose the number of products from the fields below.
          <br />
          Note: Direct Mail includes postage.
        </p>
        <p>
          *These counts may be slightly lower at time of deployment due to suppression and NCOA (National Change of Address).
        </p>
        <p>
          If you have any questions, please contact Robert Prinster @
          Robert.Prinster@ansira.com or call 1-972-426-4310
        </p>
        <div
          className="full-line-counts-disclaimer"
          style={{
            background: '#f5f5f5',
            padding: '10px',
            color: 'black',
            display: 'inline-block',
            fontWeight: 'bold'
          }}
        >
          This campaign requires each retailer to choose a direct mail quantity as well as an email quantity.
        </div>
      </section>
      {campaign.type === 'Full Line' && (
        <div
          className="full-line-counts-disclaimer"
          style={{
            background: '#f5f5f5',
            padding: '10px',
            color: 'black',
            display: 'inline-block',
            fontWeight: 'bold'
          }}
        >
          This conquest quantity is for your retailers’ full line (all
          vehicles). We will reach out to you with a more defined count.
        </div>
      )}
      <CountsBlock
        theme={theme}
        campaignType={campaign.type}
        hasClimateSelect={campaign.hasClimateSelect}
        climate={campaign.climate ? campaign.climate : null}
        images={campaign.images}
        costs={campaign.costs}
        type={campaign.type}
        counts={selectedDealer.counts}
        orderCounts={orderCounts}
        updateOrderCounts={event => updateOrderCounts(campaign, event.target)}
        updateOrderTotal={subTotals => updateOrderTotal(campaign.id, subTotals)}
        handleSecondStep={handleSecondStep}
      />
    </StyledCampaignOrderForm>
  );
};

export default CampaignOrderForm;
