import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: inline;
`;

const Button = styled.button`
  background-color: transparent;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  font-size: 16px;
  font-weight: 200;
  margin-left: 5px;
  padding: 0px;
  cursor: pointer;
`;

const EditButton = props => (
  <Div>
    <Button type="button" onClick={props.onClick}>
      {props.children}
    </Button>
  </Div>
);

export default EditButton;
