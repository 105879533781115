import React, { Component } from 'react';
import MaskedInput from 'react-input-mask';
import styled from 'styled-components';
import { breakpoints, colors } from '../style-utilities/jagVariables';
import Separator from './StepsAccordion/Separator';

// hours data
import hours from '../static-data/hours';

const StyledContactInformation = styled.div`
  .inputs {
    display: grid;
    grid-gap: 15px;
    max-width: 1050px;

    label,
    input {
      display: block;
      text-transform: uppercase;
      width: 100%;
    }

    label {
      font-size: 14px;
    }

    input {
      background: ${colors.inputGray};
      border: 1px solid ${colors.gray3};
      letter-spacing: 2px;
      padding: 12px;
    }

    .input-group {
      &:first-of-type,
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7),
      &:nth-of-type(8),
      &:nth-of-type(9) {
        grid-column: span 2;
      }
    }
  }

  .hours {
    color: ${colors.gray3};
    margin-top: 35px;
    max-width: 1050px;

    table {
      margin-bottom: 50px;
      table-layout: fixed;
      width: 100%;
    }

    thead {
      background: ${colors.textGray};
      text-transform: uppercase;

      th {
        text-align: center;

        &:first-of-type {
          width: 38%;
        }
      }
    }

    select {
      font-size: 80%;
      width: 95%;
    }

    tbody tr {
      color: ${colors.gray3};

      &:nth-of-type(odd) {
        background: ${colors.inputGray};
      }
      &:nth-of-type(even) {
        background: ${colors.textGray};
      }

      td {
        text-align: center;

        &:not(:nth-of-type(1)) {
          border-left: 1px solid ${colors.gray2};
        }
      }
    }

    input {
      background-color: ${colors.inputGray};
      border: none;
      color: ${colors.gray3};
      max-width: 60px;
      padding: 2px 5px;
      text-align: center;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .inputs {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    .hours {
      thead {
        background: ${colors.textGray};
        text-transform: uppercase;

        th {
          text-align: center;

          &:first-of-type {
            width: initial;
          }
        }
      }

      select {
        font-size: 100%;
        width: 60%;
      }
    }
  }
`;

class ContactInformation extends Component {
  state = {
    hours: {
      sunday: { open: '12:00 AM', close: '12:00 AM' },
      monday: { open: '12:00 AM', close: '12:00 AM' },
      tuesday: { open: '12:00 AM', close: '12:00 AM' },
      wednesday: { open: '12:00 AM', close: '12:00 AM' },
      thursday: { open: '12:00 AM', close: '12:00 AM' },
      friday: { open: '12:00 AM', close: '12:00 AM' },
      saturday: { open: '12:00 AM', close: '12:00 AM' }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selectedDealer && this.props.selectedDealer) {
      this.props.setHours(this.state.hours);
    }
  }

  renderHours = () => hours.map(time => <option key={time}>{time}</option>);

  handleInputChange = event => {
    this.props.updateContactInfo(event.target);
  };

  handleHoursInput = event => {
    // if input is type checkbox
    if (event.target.type === 'checkbox') {
      this.setState(
        {
          hours: {
            ...this.state.hours,
            [event.target.name]: event.target.checked
              ? { closed: true }
              : { open: '12:00 AM', close: '12:00 AM' }
          }
        },
        () => this.props.setHours(this.state.hours)
      );
    } else {
      // use the field name to parse the day and whether
      // it is the open field or the close field
      // pull each of those off of the array
      const [day, field] = event.target.name.split('-');

      this.setState(
        {
          hours: {
            ...this.state.hours,
            [day]: {
              ...this.state.hours[day],
              [field]: event.target.value
            }
          }
        },
        () => this.props.setHours(this.state.hours)
      );
    }
  };

  render() {
    const {
      name: retailer,
      firstName,
      lastName,
      email,
      tollFree,
      phone,
      count,
      address,
      city,
      state,
      zip,
      website
      // set fallback value so it doesnt fail
    } = this.props.selectedDealer || { acf: {} };

    return (
      <StyledContactInformation>
        <div className="contact-info">
          <p>
            Please verify your information. This is what will be printed on your
            advertising materials.
            <br /> Please note – first name, last name and email are only for
            contact information on this order if there are questions.
          </p>
          <div className="inputs">
            <div className="input-group">
              <label htmlFor="firstName">First Name*</label>
              <input
                id="firstName"
                type="text"
                required
                name="firstName"
                onChange={this.handleInputChange}
                value={firstName || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="lastName">Last Name*</label>
              <input
                id="lastName"
                type="text"
                required
                name="lastName"
                onChange={this.handleInputChange}
                value={lastName || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="phone">Phone*</label>

              <MaskedInput
                mask="(999) 999-9999"
                id="phone"
                type="text"
                required
                name="phone"
                onChange={this.handleInputChange}
                value={phone || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="tollFree">Toll Free Phone</label>
              <MaskedInput
                mask="(999) 999-9999"
                id="tollFree"
                type="text"
                name="tollFree"
                onChange={this.handleInputChange}
                value={tollFree || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email*</label>
              <input
                id="email"
                type="email"
                required
                name="email"
                onChange={this.handleInputChange}
                value={email || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="website">Website*</label>
              <input
                id="website"
                type="text"
                required
                name="website"
                onChange={this.handleInputChange}
                value={website || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="retailer">Retailer*</label>
              <input
                id="retailer"
                type="text"
                required
                name="retailer"
                onChange={this.handleInputChange}
                value={retailer ? retailer.replace('&#8217;', "'") : ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="address">Address</label>
              <input
                id="address"
                type="text"
                name="address"
                onChange={this.handleInputChange}
                value={address || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="city">City</label>
              <input
                id="city"
                type="text"
                name="city"
                onChange={this.handleInputChange}
                value={city || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="state">State</label>
              <input
                id="state"
                type="text"
                name="state"
                onChange={this.handleInputChange}
                value={state || ''}
              />
            </div>
            <div className="input-group">
              <label htmlFor="zip">Zip</label>
              <input
                id="zip"
                type="text"
                name="zip"
                onChange={this.handleInputChange}
                value={zip || ''}
              />
            </div>
          </div>
        </div>
        <Separator />
         <div className="hours">
          <table>
            <thead>
              <tr>
                <th colSpan="2" className="hidden-mobile">
                  Days of the Week
                </th>
                <th colSpan="1" className="visible-mobile">
                  Days of the Week
                </th>
                <th>Open</th>
                <th>Close</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      name="sunday"
                      checked={this.state.sunday}
                      onChange={this.handleHoursInput}
                    />{' '}
                    Closed
                  </label>
                </td>
                <td className="hidden-mobile">Sunday</td>
                <td>
                  <select
                    disabled={this.state.hours.sunday.closed}
                    name="sunday-open"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
                <td>
                  <select
                    disabled={this.state.hours.sunday.closed}
                    name="sunday-close"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      name="monday"
                      checked={this.state.monday}
                      onChange={this.handleHoursInput}
                    />{' '}
                    Closed
                  </label>
                </td>
                <td className="hidden-mobile">Monday</td>
                <td>
                  <select
                    disabled={this.state.hours.monday.closed}
                    name="monday-open"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
                <td>
                  <select
                    disabled={this.state.hours.monday.closed}
                    name="monday-close"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      name="tuesday"
                      checked={this.state.tuesday}
                      onChange={this.handleHoursInput}
                    />{' '}
                    Closed
                  </label>
                </td>
                <td className="hidden-mobile">Tuesday</td>
                <td>
                  <select
                    disabled={this.state.hours.tuesday.closed}
                    name="tuesday-open"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
                <td>
                  <select
                    disabled={this.state.hours.tuesday.closed}
                    name="tuesday-close"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      name="wednesday"
                      checked={this.state.wednesday}
                      onChange={this.handleHoursInput}
                    />{' '}
                    Closed
                  </label>
                </td>
                <td className="hidden-mobile">Wednesday</td>
                <td>
                  <select
                    disabled={this.state.hours.wednesday.closed}
                    name="wednesday-open"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
                <td>
                  <select
                    disabled={this.state.hours.wednesday.closed}
                    name="wednesday-close"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      name="thursday"
                      checked={this.state.thursday}
                      onChange={this.handleHoursInput}
                    />{' '}
                    Closed
                  </label>
                </td>
                <td className="hidden-mobile">Thursday</td>
                <td>
                  <select
                    disabled={this.state.hours.thursday.closed}
                    name="thursday-open"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
                <td>
                  <select
                    disabled={this.state.hours.thursday.closed}
                    name="thursday-close"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      name="friday"
                      checked={this.state.friday}
                      onChange={this.handleHoursInput}
                    />{' '}
                    Closed
                  </label>
                </td>
                <td className="hidden-mobile">Friday</td>
                <td>
                  <select
                    disabled={this.state.hours.friday.closed}
                    name="friday-open"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
                <td>
                  <select
                    disabled={this.state.hours.friday.closed}
                    name="friday-close"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      name="saturday"
                      checked={this.state.saturday}
                      onChange={this.handleHoursInput}
                    />{' '}
                    Closed
                  </label>
                </td>
                <td className="hidden-mobile">Saturday</td>
                <td>
                  <select
                    disabled={this.state.hours.saturday.closed}
                    name="saturday-open"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
                <td>
                  <select
                    disabled={this.state.hours.saturday.closed}
                    name="saturday-close"
                    onChange={this.handleHoursInput}
                  >
                    {this.renderHours()}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </StyledContactInformation>
    );
  }
}

export default ContactInformation;
