import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../style-utilities/jagVariables';
import Select from 'react-select';

const StyledDealerSelect = styled.div`
  .react-select-main {
    border-radius: 0;
    margin-bottom: 30px;

    .react-select__control {
      background: ${colors.gray5};
      border-radius: 0;
    }

    .react-select__menu {
      color: black;
      position: relative;
      z-index: 100;
    }

    .react-select__value-container {
      text-transform: uppercase;
    }
  }

  label {
    display: block;
    font-size: 1em;
    font-weight: 100;
    margin-bottom: 15px;
  }
`;

class DealerSelect extends Component {
  componentDidMount() {
    const select = this.refs.rs.select;
    if (!select.onInputBlurPatched) {
      const originalOnInputBlur = select.onInputBlur;
      select.onInputBlur = e => {
        if (
          select.menuListRef &&
          select.menuListRef.contains(document.activeElement)
        ) {
          select.inputRef.focus();
          return;
        }
        originalOnInputBlur(e);
      };
      select.onInputBlurPatched = true;
    }
  }

  dealerOptions = () => {
    const { dealers, theme } = this.props;
    // console.log(dealers);

    // let filteredDealers = dealers.filter((dealer, index) => {
    //   // console.log(dealer.node.acf.jag_cpo_dm_conquest);
    //   // console.log(theme);

    //   let cpo_dm_customer;
    //   let cpo_dm_conquest;
    //   let cpo_em_customer;
    //   let cpo_em_conquest;

    //   if (theme === 'jaguar') {
    //     cpo_dm_customer = dealer.node.acf.jag_cpo_dm_customer;
    //     cpo_dm_conquest = dealer.node.acf.jag_cpo_dm_conquest;
    //     cpo_em_customer = dealer.node.acf.jag_cpo_em_customer;
    //     cpo_em_conquest = dealer.node.acf.jag_cpo_em_conquest;
    //   } else {
    //     cpo_dm_customer = dealer.node.acf.lr_cpo_dm_customer;
    //     cpo_dm_conquest = dealer.node.acf.lr_cpo_dm_conquest;
    //     cpo_em_customer = dealer.node.acf.lr_cpo_em_customer;
    //     cpo_em_conquest = dealer.node.acf.lr_cpo_em_conquest;
    //   }

    //   if(cpo_dm_customer === null && cpo_dm_conquest === null && cpo_em_customer=== null && cpo_em_conquest === null) {
    //     // console.log('dealer removed: '+ dealer.node.acf.dealer_code);
    //     // console.log('dmCustomer:' + cpo_dm_customer + ' emCustomer: ' + cpo_em_customer);
    //     // console.log('dmCustomer:' + cpo_dm_conquest + ' emCustomer: ' + cpo_em_conquest);
    //   } else {
    //     return dealer;
    //   }
    // });

    return dealers.map(({ node: dealer }) => {
      const {
        slug,
        title,
        acf: {
          dealer_code,
          street: address,
          state,
          town: city,
          post_code: zip,
          url: website,
          phone
        }
      } = dealer;

      // counts prop is different
      // between jag and lr dealers
      const counts =
        theme === 'jaguar'
          ? {
              'F-TYPE': {
                dmConquest: dealer.acf.jag_ftype_dm_conquest,
                emConquest: dealer.acf.jag_ftype_em_conquest
              },
              'Approved Certified Pre-Owned Sales Event': {
                dmCustomer: dealer.acf.jag_cpo_dm_customer,
                dmConquest: dealer.acf.jag_cpo_dm_conquest,
                emCustomer: dealer.acf.jag_cpo_em_customer,
                emConquest: dealer.acf.jag_cpo_em_conquest
              },
              'Full Line': {
                dmConquest: dealer.acf.jag_full_dm_conquest,
                emConquest: dealer.acf.jag_full_em_conquest
              },
              'Holiday Sales Event': {
                dmConquest: dealer.acf.jag_hse_dm_conquest,
                emConquest: dealer.acf.jag_hse_em_conquest
              },
              'I-PACE': {
                dmCustomer: 0,
                dmConquest: dealer.acf.jag_ipace_dm_conquest,
                emCustomer: 0,
                emConquest: dealer.acf.jag_ipace_em_conquest
              }
            }
          : {
              'Land Rover Discovery Campaign': {
                dmConquest: dealer.acf.lr_hse_dm_conquest_counts,
                emConquest: dealer.acf.lr_hse_em_conquest_counts
              },
              'Approved Certified Pre-Owned Sales Event': {
                dmCustomer: dealer.acf.lr_cpo_dm_customer,
                dmConquest: dealer.acf.lr_cpo_dm_conquest,
                emCustomer: dealer.acf.lr_cpo_em_customer,
                emConquest: dealer.acf.lr_cpo_em_conquest
              },
              'Tax Advantage': {
                dmCustomer: dealer.acf.lr_2019tax_dm_customer,
                dmConquest: dealer.acf.lr_2019tax_dm_conquest,
                emCustomer: dealer.acf.lr_2019tax_em_customer,
                emConquest: dealer.acf.lr_2019tax_em_conquest,
                postCustomer: dealer.acf.lr_2019tax_post_customer,
                postConquest: dealer.acf.lr_2019tax_post_conquest
              }
            };

      return {
        value: slug,
        name: `${dealer_code} - ${title}`,
        label: `${dealer_code} - ${title}`.replace('&#8217;', "'"),
        counts,
        address,
        state,
        city,
        zip,
        website: website.toLowerCase(),
        phone
      };
    });
  };

  render() {
    const { selectedDealer, handleDealerSelect } = this.props;

    return (
      <StyledDealerSelect>
        <label>
          Select your Retailer to get started with one or more campaigns
        </label>
        <Select
          ref="rs"
          className="react-select-main"
          classNamePrefix="react-select"
          placeholder="SELECT ONE"
          value={selectedDealer}
          onChange={handleDealerSelect}
          options={this.dealerOptions()}
        />
      </StyledDealerSelect>
    );
  }
}

export default DealerSelect;
