import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../style-utilities/jagVariables';

const StyledCampaignBlock = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    max-width: 100%;
    margin-bottom: 1rem;
  }
  
  .hidden {
    display: none;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

const CampaignBlock = ({ headline, description, optionalFlag }) => {
  return (
    <StyledCampaignBlock>
      <h3 className={`${optionalFlag ? 'hidden' : ''}`}>{headline}</h3>
      <p className={`${optionalFlag ? 'hidden' : ''}`}>{description}</p>
    </StyledCampaignBlock>
  );
};

export default CampaignBlock;
