import React, { Component } from 'react';
import axios from 'axios';

/**
 * this component is all logic and state
 * the "template" is written using a "render" prop
 * within the page it will be used
 * ** see jaguar-hse-2018.jsx starting at line 69 for example
 */
class StepsAccordion extends Component {
  state = {
    activeStep: 1,
    validSteps: [],
    selectedDealer: null,
    coopFunds: false,
    showCampaignMenus: false,
    activeCampaigns: {},
    subTotals: {},
    canProceed: false,
    linkedCampaignExpand: false,
    submissionSuccessful: false,
    secondStepCanProceed: false,
    handleSecondStep: (value) => {
      this.setState({
        secondStepCanProceed: value
      });
    },
    handleLinkedCampaign: linkedCampaignExpand => {
      // check to see if this campaign is linked to another and syncs expanded flag
      this.setState({
        linkedCampaignExpand: !this.state.linkedCampaignExpand
      });
    },
    handleOptionalCampaign: campaignId => {
      // check to see if an optional campaign is used, if not remove from activeCampaigns
      Object.keys(this.state.activeCampaigns).forEach(key => {
        if (this.state.activeCampaigns[key].attributes === undefined) {
          this.state.selectCampaign(this.state.activeCampaigns[key]);
        }
      });
    },
    /**
     * set the selected dealer to state
     */
    handleDealerSelect: selectedDealer => {
      this.setState({
        selectedDealer,
        showCampaignMenus: true
      });
    },
    toggleCoopFunds: () => {
      this.setState({
        coopFunds: !this.state.coopFunds
      });
    },
    /**
     * Add and remove campaigns from activeCampaigns
     */
    selectCampaign: campaign => {
      // if the campaign is already selected, deselect it
      // if it's not, add it to activeCampaigns
      if (!this.state.activeCampaigns.hasOwnProperty(campaign.id)) {
        this.setState({
          activeCampaigns: {
            ...this.state.activeCampaigns,
            [campaign.id]: campaign
          }
        });
      } else {
        const {
          [campaign.id]: removed,
          ...newState
        } = this.state.activeCampaigns;

        this.setState({
          activeCampaigns: newState
        });
      }
    },
    /**
     * update attributes of a campaign
     */
    updateCampaignAttr: (campaign, input) => {
      if (input.name === 'climate') {
        this.setState({
          activeCampaigns: {
            ...this.state.activeCampaigns,
            [campaign.id]: {
              ...this.state.activeCampaigns[campaign.id],
              climate: input.value
            }
          }
        });
      } else {
        this.setState({
          activeCampaigns: {
            ...this.state.activeCampaigns,
            [campaign.id]: {
              ...this.state.activeCampaigns[campaign.id],
              attributes: {
                ...this.state.activeCampaigns[campaign.id].attributes,
                [input.name]: input.value
              }
            }
          }
        });
      }
    },
    setNationalOfferAttr: (data, campaign) => {
      const attrs = {
        vehicle: this.state.activeCampaigns[campaign].vehicle,
        ...data
      };

      this.setState({
        useNationalOffer: data !== undefined,
        activeCampaigns: {
          ...this.state.activeCampaigns,
          [campaign]: {
            ...this.state.activeCampaigns[campaign],
            attributes: attrs
          }
        }
      });
    },
    setHiddenFields: (data, campaign) => {
      const hiddenFields = {
        [`${data.campaign}_vehicle`]: data.displayName,
        [`${data.campaign}_acquisition_fee`]: data.acquisition_fee,
        [`${data.campaign}_xxxxModels`]: data.xxxx_models,
        [`${data.campaign}_msrp`]: data.msrp,
        [`${data.campaign}_totalOfLeasePayments`]:
          data.total_of_lease_payments || 'N/A'
      };
      const vehicle = data.displayName;

      return new Promise((resolve, reject) => {
        this.setState(
          {
            activeCampaigns: {
              ...this.state.activeCampaigns,
              [campaign]: {
                ...this.state.activeCampaigns[campaign],
                vehicle,
                hiddenFields
              }
            }
          },
          () => {
            resolve();
          }
        );
      });
    },
    /**
     * update order counts for a campaign
     */
    updateOrderCounts: (campaign, input) => {
      this.setState({
        activeCampaigns: {
          ...this.state.activeCampaigns,
          [campaign.id]: {
            ...this.state.activeCampaigns[campaign.id],
            counts: {
              ...this.state.activeCampaigns[campaign.id].counts,
              [input.name]: input.value
            }
          }
        }
      });
    },
    updateOrderTotal: (campaign, subTotals) => {
      const valuesArr = Object.values(subTotals);
      const amountToAdd = valuesArr.reduce((total, add) => {
        return parseFloat(total) + parseFloat(add);
      }, 0);

      this.setState(
        {
          subTotals: {
            ...this.state.subTotals,
            [campaign]: amountToAdd
          }
        },
        () => {
          const subTotalsArr = Object.values(this.state.subTotals);
          const newTotal = subTotalsArr.reduce((total, add) => {
            return parseFloat(total) + parseFloat(add);
          }, 0);

          this.setState({
            orderTotal: newTotal
          });
        }
      );
    },
    updateContactInfo: input => {
      this.setState({
        selectedDealer: {
          ...this.state.selectedDealer,
          [input.name]: input.value
        }
      });
    },
    setHours: hours => {
      this.setState({
        selectedDealer: {
          ...this.state.selectedDealer,
          hours: hours
        }
      });
    },
    /**
     * proceed to the next step of the accordion and set validation
     */
    proceedToNextStep: currentStep => {
      this.setState(
        {
          activeStep: currentStep + 1,
          validSteps: this.state.validSteps.includes(currentStep)
            ? [...this.state.validSteps]
            : [...this.state.validSteps, currentStep]
        },
        this.scrollToElementAfterAnimation()
      );
    },
    /**
     * edit an accordion step
     */
    editStep: stepToEdit => {
      this.setState(
        {
          activeStep: stepToEdit
        },
        this.scrollToElementAfterAnimation()
      );
    },
    /**
     * submit the order
     */
    submitOrder: event => {
      event.preventDefault();
      const {
        activeCampaigns,
        selectedDealer,
        coopFunds,
        orderTotal,
        useNationalOffer
      } = this.state;
      // set campaigns with fallback
      const hseCampaign = activeCampaigns['2018-hse-campaign'] || {
        attributes: {},
        costs: {},
        counts: {}
      };
      const ftypeCampaign = activeCampaigns[
        '2018-end-of-year-f-type-campaign'
      ] || {
          attributes: {},
          costs: {},
          counts: {}
        };
      const fullLineCampaign = activeCampaigns[
        '2018-end-of-year-full-line-campaign'
      ] || {
          attributes: {},
          costs: {},
          counts: {}
        };
      const certPreOwnedCampaign = activeCampaigns[
        '2019-pre-owned-1'
      ] || {
          attributes: {},
          costs: {},
          counts: {}
        };
      const certPreOwnedOptCampaign = activeCampaigns[
        '2019-pre-owned-2'
      ] || {
          attributes: {},
          costs: {},
          counts: {}
        };
      const ipaceCampaign = activeCampaigns[
        'I-PACE'
      ] || {
          attributes: {},
          costs: {},
          counts: {}
        };
      const taxAdvCampaign = activeCampaigns[
        '2019-tax-advantage'
      ] || {
          attributes: {},
          costs: {},
          counts: {}
        };
      const totals = {
        hse_dmConquestTotal:
          (hseCampaign.costs.dm * hseCampaign.counts.dmConquestCounts).toFixed(
            2
          ) || 'N/A',
        hse_emConquestTotal:
          (
            hseCampaign.costs.email * hseCampaign.counts.emailConquestCounts
          ).toFixed(2) || 'N/A',
        hse_total:
          (
            hseCampaign.costs.email * hseCampaign.counts.emailConquestCounts +
            hseCampaign.costs.dm * hseCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        ftype_dmConquestTotal:
          (
            ftypeCampaign.costs.dm * ftypeCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        ftype_emConquestTotal:
          (
            ftypeCampaign.costs.email * ftypeCampaign.counts.emailConquestCounts
          ).toFixed(2) || 'N/A',
        ftype_total:
          (
            ftypeCampaign.costs.email *
            ftypeCampaign.counts.emailConquestCounts +
            ftypeCampaign.costs.dm * ftypeCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        fullLine_dmConquestTotal:
          (
            fullLineCampaign.costs.triDm *
            fullLineCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        fullLine_emConquestTotal:
          (
            fullLineCampaign.costs.email *
            fullLineCampaign.counts.emailConquestCounts
          ).toFixed(2) || 'N/A',
        fullLine_total:
          (
            fullLineCampaign.costs.email *
            fullLineCampaign.counts.emailConquestCounts +
            fullLineCampaign.costs.triDm *
            fullLineCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        certPreOwnedCampaign_dmConquestTotal:
          (
            certPreOwnedCampaign.costs.dm *
            certPreOwnedCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        certPreOwnedCampaign_emConquestTotal:
          (
            certPreOwnedCampaign.costs.email *
            certPreOwnedCampaign.counts.emailConquestCounts
          ).toFixed(2) || 'N/A',
        certPreOwnedCampaign_dmCustomerTotal:
          (
            certPreOwnedCampaign.costs.dm *
            certPreOwnedCampaign.counts.dmCustomerCounts
          ).toFixed(2) || 'N/A',
        certPreOwnedCampaign_emCustomerTotal:
          (
            certPreOwnedCampaign.costs.email *
            certPreOwnedCampaign.counts.emailCustomerCounts
          ).toFixed(2) || 'N/A',
        certPreOwnedCampaign_total:
          (
            certPreOwnedCampaign.costs.email *
            certPreOwnedCampaign.counts.emailConquestCounts + certPreOwnedCampaign.costs.dm *
            certPreOwnedCampaign.counts.dmConquestCounts
            + certPreOwnedCampaign.costs.dm *
            certPreOwnedCampaign.counts.dmCustomerCounts
            + certPreOwnedCampaign.costs.email *
            certPreOwnedCampaign.counts.emailCustomerCounts
          ).toFixed(2) || 'N/A',
        ipace_dmConquestTotal:
          (
            ipaceCampaign.costs.dm *
            ipaceCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        ipace_emConquestTotal:
          (
            ipaceCampaign.costs.email *
            ipaceCampaign.counts.emailConquestCounts
          ).toFixed(2) || 'N/A',
        ipace_dmCustomerTotal:
          (
            ipaceCampaign.costs.dm *
            ipaceCampaign.counts.dmCustomerCounts
          ).toFixed(2) || 'N/A',
        ipace_emCustomerTotal:
          (
            ipaceCampaign.costs.email *
            ipaceCampaign.counts.emailCustomerCounts
          ).toFixed(2) || 'N/A',
        ipace_total:
          (
            ipaceCampaign.costs.email *
            ipaceCampaign.counts.emailConquestCounts + ipaceCampaign.costs.dm *
            ipaceCampaign.counts.dmConquestCounts
            + ipaceCampaign.costs.dm *
            ipaceCampaign.counts.dmCustomerCounts
            + ipaceCampaign.costs.email *
            ipaceCampaign.counts.emailCustomerCounts
          ).toFixed(2) || 'N/A',
        taxAdv_dmConquestTotal:
          (
            taxAdvCampaign.costs.dm *
            taxAdvCampaign.counts.dmConquestCounts
          ).toFixed(2) || 'N/A',
        taxAdv_emConquestTotal:
          (
            taxAdvCampaign.costs.email *
            taxAdvCampaign.counts.emailConquestCounts
          ).toFixed(2) || 'N/A',
        taxAdv_dmCustomerTotal:
          (
            taxAdvCampaign.costs.dm *
            taxAdvCampaign.counts.dmCustomerCounts
          ).toFixed(2) || 'N/A',
        taxAdv_emCustomerTotal:
          (
            taxAdvCampaign.costs.email *
            taxAdvCampaign.counts.emailCustomerCounts
          ).toFixed(2) || 'N/A',
        taxAdv_postCustomerTotal:
          (
            taxAdvCampaign.costs.post *
            taxAdvCampaign.counts.postCustomerCounts
          ).toFixed(2) || 0,
        taxAdv_postConquestTotal:
          (
            taxAdvCampaign.costs.post *
            taxAdvCampaign.counts.postConquestCounts
          ).toFixed(2) || 0,
        taxAdv_total:
          (
            taxAdvCampaign.costs.email * taxAdvCampaign.counts.emailConquestCounts
            + taxAdvCampaign.costs.dm * taxAdvCampaign.counts.dmConquestCounts
            + taxAdvCampaign.costs.dm * taxAdvCampaign.counts.dmCustomerCounts
            + taxAdvCampaign.costs.email * taxAdvCampaign.counts.emailCustomerCounts
            + taxAdvCampaign.costs.post * taxAdvCampaign.counts.postCustomerCounts
            + taxAdvCampaign.costs.post * taxAdvCampaign.counts.postConquestCounts
          ).toFixed(2) || 'N/A',
      };
      const renderCampaignTypes = () => {
        const typesArray = Object.keys(this.state.activeCampaigns)
          .map(campaign => this.state.activeCampaigns[campaign])
          .sort((a, b) => a.order - b.order)
          .map(campaign => campaign.type);

        return typesArray.join(', ');
      };
      const emailList = () => {
        if (
          !window.location.href.includes('ansira') &&
          !window.location.href.includes('localhost')
        ) {
          return [
            'Robert.Prinster@ansira.com',
            'Joe.Maloney@ansira.com',
            'Susan.Coyne@Ansira.com',
            'lexa.burciaga@ansira.com',
            'lisa.gaertner@ansira.com',
            'jeff.hall@ansira.com',
            "suzanne.spada@ansira.com",
            'kaylen.stix@ansira.com',
            selectedDealer.email
          ];
        } else {
          console.log('sending non-production email list: ' + selectedDealer.email);
          return [
            'kaylen.stix@ansira.com',
            'aldo.lugo@Ansira.com',
            'lexa.burciaga@ansira.com',
            'lisa.gaertner@ansira.com',
            'jeff.hall@ansira.com',
            'byron.chang@sq1.com',
            selectedDealer.email
          ];
        }
      };
      const emailTemplate = () => {
        const theme = this.props.theme;
        if (
          !window.location.href.includes('ansira') &&
          !window.location.href.includes('localhost')
        ) {
          // if non local, dev, or stage, use production email template
          // console.log('Sending prod email: ' + theme);
          if (theme === 'jaguar') {
            return 'email-templates/jlr-landing-pages/prod-jaguar.html';
          } else {
            return 'email-templates/jlr-landing-pages/prod-landrover.html';
          }
        } else {
          // if local, dev, or stage, use dev email template
          console.log('Sending dev email: ' + theme);
          if (theme === 'jaguar') {
            return 'email-templates/jlr-landing-pages/dev-jaguar.html';
          } else {
            return 'email-templates/jlr-landing-pages/dev-landrover.html';
          }

        }
      };

      const emailRequestBody =
        this.props.theme === 'jaguar'
          ? {
            to: emailList(),
            bcc: [],
            cc: [],
            source: 'Jaguar Retailer Campaigns <info@ansira.io>',
            html: '<h1>Jaguar Retailer Campaigns</h1>',
            subject: 'Order Confirmation: Jaguar Retailer Campaigns',
            templateName: emailTemplate(),
            leadData: {
              global_campaign: 'Approved Certified Pre-Owned Sales Event',
              dealership: selectedDealer.name,
              firstName: selectedDealer.firstName,
              lastName: selectedDealer.lastName,
              phone: selectedDealer.phone,
              tollFree: selectedDealer.tollFree || 'N/A',
              email: selectedDealer.email,
              web: selectedDealer.website,
              address: selectedDealer.address,
              city: selectedDealer.city,
              state: selectedDealer.state,
              zip: selectedDealer.zip,
              hoursSun: selectedDealer.hours.sunday.closed
                ? 'Closed'
                : `${selectedDealer.hours.sunday.open} - ${
                selectedDealer.hours.sunday.close
                }`,
              hoursMon: selectedDealer.hours.monday.closed
                ? 'Closed'
                : `${selectedDealer.hours.monday.open} - ${
                selectedDealer.hours.monday.close
                }`,
              hoursTue: selectedDealer.hours.tuesday.closed
                ? 'Closed'
                : `${selectedDealer.hours.tuesday.open} - ${
                selectedDealer.hours.tuesday.close
                }`,
              hoursWed: selectedDealer.hours.wednesday.closed
                ? 'Closed'
                : `${selectedDealer.hours.wednesday.open} - ${
                selectedDealer.hours.wednesday.close
                }`,
              hoursThurs: selectedDealer.hours.thursday.closed
                ? 'Closed'
                : `${selectedDealer.hours.thursday.open} - ${
                selectedDealer.hours.thursday.close
                }`,
              hoursFri: selectedDealer.hours.friday.closed
                ? 'Closed'
                : `${selectedDealer.hours.friday.open} - ${
                selectedDealer.hours.friday.close
                }`,
              hoursSat: selectedDealer.hours.saturday.closed
                ? 'Closed'
                : `${selectedDealer.hours.saturday.open} - ${
                selectedDealer.hours.saturday.close
                }`,
              campaigns: renderCampaignTypes(),
              coopFunds: coopFunds,

              // hse fields
              hse_vehicle: hseCampaign.attributes.vehicle || 'N/A',
              hse_climate: hseCampaign.climate || 'N/A',
              hse_nationalOffer: useNationalOffer || 'No',
              hse_leaseOffer: hseCampaign.attributes.leaseOffer || 'N/A',
              hse_monthTerm: hseCampaign.attributes.monthTerm || 'N/A',
              hse_cashDueAtSigning:
                hseCampaign.attributes.dueAtSigning || 'N/A',
              hse_customerCredit:
                hseCampaign.attributes.customerCredit || 'N/A',
              hse_securityDeposit:
                hseCampaign.attributes.securityDeposit || 'N/A',
              hse_apr: hseCampaign.attributes.apr || 'N/A',
              hse_aprMonth: hseCampaign.attributes.aprMonths || 'N/A',
              hse_aprCustomerCredit:
                hseCampaign.attributes.aprCustomerCredit || 'N/A',
              hse_dmConquestCounts: hseCampaign.counts.dmConquestCounts || 0,
              hse_dmCustomerCounts: 0,
              hse_dmCustomerCost: 0,
              hse_emConquestCounts:
                hseCampaign.counts.emailConquestCounts || 0,
              hse_emCustomerCounts: 0,
              hse_dmConquestCost: hseCampaign.costs.dm || 'N/A',
              hse_emConquestCost: hseCampaign.costs.email || 'N/A',
              hse_emCustomerCost: 0,
              hse_dmConquestTotal: totals.hse_dmConquestTotal || '',
              hse_emConquestTotal: totals.hse_emConquestTotal,
              hse_dmCustomerTotal: 0,
              hse_emCustomerTotal: 0,
              hse_total: totals.hse_total,

              // ftype
              ftype_vehicle: ftypeCampaign.attributes.vehicle || 'N/A',
              ftype_leaseOffer: ftypeCampaign.attributes.leaseOffer || 'N/A',
              ftype_monthTerm: ftypeCampaign.attributes.monthTerm || 'N/A',
              ftype_cashDueAtSigning:
                ftypeCampaign.attributes.dueAtSigning || 'N/A',
              ftype_securityDeposit:
                ftypeCampaign.attributes.securityDeposit || 'N/A',
              ftype_apr: ftypeCampaign.attributes.apr || 'N/A',
              ftype_aprMonth: ftypeCampaign.attributes.aprMonths || 'N/A',
              ftype_acquisitionFee: ftypeCampaign.attributes.acquisitionFee || 'N/A',
              ftype_msrp: ftypeCampaign.attributes.msrp || 'N/A',
              ftype_totalOfLeasePayments: ftypeCampaign.attributes.totalOfLeasePayments || 'N/A',
              ftype_dmConquestCounts:
                ftypeCampaign.counts.dmConquestCounts || 0,
              ftype_dmCustomerCounts: 0,
              ftype_dmConquestCost: ftypeCampaign.costs.dm || 0,
              ftype_dmCustomerCost: 0,
              ftype_emConquestCost: ftypeCampaign.costs.email || 0,
              ftype_emCustomerCost: 0,
              ftype_emConquestCounts:
                ftypeCampaign.counts.emailConquestCounts || 0,
              ftype_emCustomerCounts: 0,
              ftype_dmConquestTotal: totals.ftype_dmConquestTotal,
              ftype_emConquestTotal: totals.ftype_emConquestTotal,
              ftype_dmCustomerTotal: 0,
              ftype_emCustomerTotal: 0,
              ftype_total: totals.ftype_total || 0,

              // full line
              fullLine_vehicle: fullLineCampaign.attributes.vehicle || 'N/A',
              fullLine_leaseOffer:
                fullLineCampaign.attributes.leaseOffer || 'N/A',
              fullLine_monthTerm:
                fullLineCampaign.attributes.monthTerm || 'N/A',
              fullLine_cashDueAtSigning:
                fullLineCampaign.attributes.dueAtSigning || 'N/A',
              fullLine_securityDeposit:
                fullLineCampaign.attributes.securityDeposit || 'N/A',
              fullLine_apr: fullLineCampaign.attributes.apr || 'N/A',
              fullLine_aprMonth:
                fullLineCampaign.attributes.aprMonths || 'N/A',
              fullLine_acquisitionFee: fullLineCampaign.attributes.acquisitionFee || 'N/A',
              fullLine_msrp: fullLineCampaign.attributes.msrp || 'N/A',
              fullLine_totalOfLeasePayments: fullLineCampaign.attributes.totalOfLeasePayments || 'N/A',
              fullLine_dmConquestCounts:
                fullLineCampaign.counts.dmConquestCounts || 0,
              fullLine_dmCustomerCounts: 0,
              fullLine_emConquestCounts:
                fullLineCampaign.counts.emailConquestCounts || 0,
              fullLine_emCustomerCounts: 0,
              fullLine_dmConquestCost: fullLineCampaign.costs.triDm || 0,
              fullLine_dmCustomerCost: 0,
              fullLine_emConquestCost: fullLineCampaign.costs.email || 0,
              fullLine_emCustomerCost: 0,
              fullLine_dmConquestTotal: totals.fullLine_dmConquestTotal,
              fullLine_emConquestTotal: totals.fullLine_emConquestTotal,
              fullLine_dmCustomerTotal: 0,
              fullLine_emCustomerTotal: 0,
              fullLine_total: totals.fullLine_total || 0,

              // pre-sale
              certPreOwned_vehicle: certPreOwnedCampaign.attributes.vehicle || 'N/A',
              certPreOwned_apr: certPreOwnedCampaign.attributes.apr || 'N/A',
              certPreOwned_aprMonth: certPreOwnedCampaign.attributes.aprMonths || 'N/A',
              certPreOwned_acquisitionFee: certPreOwnedCampaign.attributes.acquisitionFee || 'N/A',
              certPreOwned_monthTerm: certPreOwnedCampaign.attributes.monthTerm || 'N/A',
              certPreOwned_msrp: certPreOwnedCampaign.attributes.msrp || 'N/A',
              certPreOwned_dmConquestCounts: certPreOwnedCampaign.counts.dmConquestCounts || 0,
              certPreOwned_dmCustomerCounts: certPreOwnedCampaign.counts.dmCustomerCounts || 0,
              certPreOwned_emConquestCounts: certPreOwnedCampaign.counts.emailConquestCounts || 0,
              certPreOwned_emCustomerCounts: certPreOwnedCampaign.counts.emailCustomerCounts || 0,
              certPreOwned_dmConquestCost: certPreOwnedCampaign.costs.dm || 0,
              certPreOwned_dmCustomerCost: certPreOwnedCampaign.costs.dm || 0,
              certPreOwned_emConquestCost: certPreOwnedCampaign.costs.email || 0,
              certPreOwned_emCustomerCost: certPreOwnedCampaign.costs.email || 0,
              certPreOwned_dmConquestTotal: totals.certPreOwnedCampaign_dmConquestTotal || 0,
              certPreOwned_emConquestTotal: totals.certPreOwnedCampaign_emConquestTotal || 0,
              certPreOwned_dmCustomerTotal: totals.certPreOwnedCampaign_dmCustomerTotal || 0,
              certPreOwned_emCustomerTotal: totals.certPreOwnedCampaign_emCustomerTotal || 0,
              certPreOwned_total: totals.certPreOwnedCampaign_total || 0,

              // pre-sale optional
              certPreOwnedOpt_vehicle: (certPreOwnedOptCampaign.attributes.vehicle == undefined) ? 'N/A' : certPreOwnedOptCampaign.attributes.vehicle,
              certPreOwnedOpt_apr: certPreOwnedOptCampaign.attributes.apr || 'N/A',
              certPreOwnedOpt_aprMonth: certPreOwnedOptCampaign.attributes.aprMonths || 'N/A',
              certPreOwnedOpt_acquisitionFee: certPreOwnedOptCampaign.attributes.acquisitionFee || 'N/A',
              certPreOwnedOpt_monthTerm: certPreOwnedOptCampaign.attributes.monthTerm || 'N/A',
              certPreOwnedOpt_msrp: certPreOwnedOptCampaign.attributes.msrp || 'N/A',
              certPreOwnedOpt_dmConquestCounts: certPreOwnedOptCampaign.counts.dmConquestCounts || 0,
              certPreOwnedOpt_dmCustomerCounts: 0,
              certPreOwnedOpt_emConquestCounts: certPreOwnedOptCampaign.counts.emailConquestCounts || 0,
              certPreOwnedOpt_emCustomerCounts: 0,
              certPreOwnedOpt_dmConquestCost: certPreOwnedOptCampaign.costs.biDm || 0,
              certPreOwnedOpt_dmCustomerCost: 0,
              certPreOwnedOpt_emConquestCost: certPreOwnedOptCampaign.costs.email || 0,
              certPreOwnedOpt_emCustomerCost: 0,
              certPreOwnedOpt_dmConquestTotal: totals.certPreOwnedOpt_dmConquestTotal || 0,
              certPreOwnedOpt_emConquestTotal: totals.certPreOwnedOpt_emConquestTotal || 0,
              certPreOwnedOpt_dmCustomerTotal: 0,
              certPreOwnedOpt_emCustomerTotal: 0,
              certPreOwnedOpt_total: totals.certPreOwnedOpt_total || 0,

              // ipace
              ipace_nationalOffer: useNationalOffer || 'No',
              ipace_vehicle: (ipaceCampaign.attributes.vehicle == undefined) ? 'N/A' : ipaceCampaign.attributes.vehicle,
              ipace_apr: ipaceCampaign.attributes.apr || 'N/A',
              ipace_aprMonth: ipaceCampaign.attributes.aprMonths || 'N/A',
              ipace_acquisitionFee: ipaceCampaign.attributes.acquisitionFee || 'N/A',
              ipace_monthTerm: ipaceCampaign.attributes.monthTerm || 'N/A',
              ipace_msrp: ipaceCampaign.attributes.msrp || 'N/A',
              ipace_amountDown: ipaceCampaign.attributes.amountDown || 'NA',
              ipace_amountPerMonth: ipaceCampaign.attributes.amountPerMonth || 'NA',
              ipace_securityDeposit: ipaceCampaign.attributes.securityDeposit || 'NA',
              ipace_dmConquestCounts: ipaceCampaign.counts.dmConquestCounts || 0,
              ipace_dmCustomerCounts: 0,
              ipace_emConquestCounts: ipaceCampaign.counts.emailConquestCounts || 0,
              ipace_emCustomerCounts: 0,
              ipace_dmConquestCost: ipaceCampaign.costs.dm || 0,
              ipace_dmCustomerCost: 0,
              ipace_emConquestCost: ipaceCampaign.costs.email || 0,
              ipace_emCustomerCost: 0,
              ipace_dmConquestTotal: totals.ipace_dmConquestTotal || 0,
              ipace_emConquestTotal: totals.ipace_emConquestTotal || 0,
              ipace_dmCustomerTotal: 0,
              ipace_emCustomerTotal: 0,
              ipace_total: totals.ipace_total || 0,
              ipace_selected: (totals.ipace_total > 0) ? 'Yes' : 'No',

              orderTotal: ((orderTotal == undefined) ? 0.00 : orderTotal.toFixed(2))
            }
          }
          : {
            to: emailList(),
            bcc: [],
            cc: [],
            source: 'Land Rover Retailer Campaigns <info@ansira.io>',
            html: '<h1>Hello World</h1>',
            subject: 'Order Confirmation: Land Rover Retailer Campaigns',
            templateName: emailTemplate(),
            leadData: {
              global_campaign: 'JLR Multi-campaign',
              dealership: selectedDealer.name,
              firstName: selectedDealer.firstName,
              lastName: selectedDealer.lastName,
              phone: selectedDealer.phone,
              tollFree: selectedDealer.tollFree || 'N/A',
              email: selectedDealer.email,
              web: selectedDealer.website,
              address: selectedDealer.address,
              city: selectedDealer.city,
              state: selectedDealer.state,
              zip: selectedDealer.zip,
              hoursSun: selectedDealer.hours.sunday.closed
                ? 'Closed'
                : `${selectedDealer.hours.sunday.open} - ${
                selectedDealer.hours.sunday.close
                }`,
              hoursMon: selectedDealer.hours.monday.closed
                ? 'Closed'
                : `${selectedDealer.hours.monday.open} - ${
                selectedDealer.hours.monday.close
                }`,
              hoursTue: selectedDealer.hours.tuesday.closed
                ? 'Closed'
                : `${selectedDealer.hours.tuesday.open} - ${
                selectedDealer.hours.tuesday.close
                }`,
              hoursWed: selectedDealer.hours.wednesday.closed
                ? 'Closed'
                : `${selectedDealer.hours.wednesday.open} - ${
                selectedDealer.hours.wednesday.close
                }`,
              hoursThurs: selectedDealer.hours.thursday.closed
                ? 'Closed'
                : `${selectedDealer.hours.thursday.open} - ${
                selectedDealer.hours.thursday.close
                }`,
              hoursFri: selectedDealer.hours.friday.closed
                ? 'Closed'
                : `${selectedDealer.hours.friday.open} - ${
                selectedDealer.hours.friday.close
                }`,
              hoursSat: selectedDealer.hours.saturday.closed
                ? 'Closed'
                : `${selectedDealer.hours.saturday.open} - ${
                selectedDealer.hours.saturday.close
                }`,
              campaigns: `Land Rover Discovery Campaign`,
              coopFunds: coopFunds,
              // hse fields
              hse_vehicle: hseCampaign.attributes.vehicle || 'N/A',
              hse_climate: hseCampaign.climate || 'N/A',
              hse_nationalOffer: useNationalOffer || 'No',
              hse_leaseOffer: hseCampaign.attributes.leaseOffer || 'N/A',
              hse_monthTerm: hseCampaign.attributes.monthTerm || 'N/A',
              hse_cashDueAtSigning:
                hseCampaign.attributes.dueAtSigning || 'N/A',
              hse_customerCredit:
                hseCampaign.attributes.customerCredit || 'N/A',
              hse_securityDeposit:
                hseCampaign.attributes.securityDeposit || 'N/A',
              hse_apr: hseCampaign.attributes.apr || 'N/A',
              hse_aprMonth: hseCampaign.attributes.aprMonths || 'N/A',
              hse_aprCustomerCredit:
                hseCampaign.attributes.aprCustomerCredit || 'N/A',
              hse_dmConquestCounts: hseCampaign.counts.dmConquestCounts || 0,
              hse_dmCustomerCounts: 0,
              hse_dmCustomerCost: 0,
              hse_emConquestCounts:
                hseCampaign.counts.emailConquestCounts || 0,
              hse_emCustomerCounts: 0,
              hse_dmConquestCost: hseCampaign.costs.dm || 'N/A',
              hse_emConquestCost: hseCampaign.costs.email || 'N/A',
              hse_emCustomerCost: 0,
              hse_dmConquestTotal: totals.hse_dmConquestTotal,
              hse_emConquestTotal: totals.hse_emConquestTotal,
              hse_dmCustomerTotal: 0,
              hse_emCustomerTotal: 0,
              hse_total: totals.hse_total,
              orderTotal: ((orderTotal == undefined) ? 0.00 : orderTotal.toFixed(2)),

              //pre-sale
              certPreOwned_vehicle: certPreOwnedCampaign.attributes.vehicle || 'N/A',
              certPreOwned_apr: certPreOwnedCampaign.attributes.apr || 'N/A',
              certPreOwned_aprMonth: certPreOwnedCampaign.attributes.aprMonths || 'N/A',
              certPreOwned_acquisitionFee: certPreOwnedCampaign.attributes.acquisitionFee || 'N/A',
              certPreOwned_monthTerm: certPreOwnedCampaign.attributes.monthTerm || 'N/A',
              certPreOwned_msrp: certPreOwnedCampaign.attributes.msrp || 'N/A',
              certPreOwned_dmConquestCounts: certPreOwnedCampaign.counts.dmConquestCounts || 0,
              certPreOwned_dmCustomerCounts: certPreOwnedCampaign.counts.dmCustomerCounts || 0,
              certPreOwned_emConquestCounts: certPreOwnedCampaign.counts.emailConquestCounts || 0,
              certPreOwned_emCustomerCounts: certPreOwnedCampaign.counts.emailCustomerCounts || 0,
              certPreOwned_dmConquestCost: certPreOwnedCampaign.costs.dm || 0,
              certPreOwned_dmCustomerCost: certPreOwnedCampaign.costs.dm || 0,
              certPreOwned_emConquestCost: certPreOwnedCampaign.costs.email || 0,
              certPreOwned_emCustomerCost: certPreOwnedCampaign.costs.email || 0,
              certPreOwned_dmConquestTotal: totals.certPreOwnedCampaign_dmConquestTotal || 0,
              certPreOwned_emConquestTotal: totals.certPreOwnedCampaign_emConquestTotal || 0,
              certPreOwned_dmCustomerTotal: totals.certPreOwnedCampaign_dmCustomerTotal || 0,
              certPreOwned_emCustomerTotal: totals.certPreOwnedCampaign_emCustomerTotal || 0,
              certPreOwned_total: totals.certPreOwnedCampaign_total || 0,
              orderTotal: ((orderTotal == undefined) ? 0.00 : orderTotal.toFixed(2)),

              // pre-sale optional
              certPreOwnedOpt_vehicle: certPreOwnedOptCampaign.attributes.vehicle || 'N/A',
              certPreOwnedOpt_apr: certPreOwnedOptCampaign.attributes.apr || 'N/A',
              certPreOwnedOpt_aprMonth: certPreOwnedOptCampaign.attributes.aprMonths || 'N/A',
              certPreOwnedOpt_acquisitionFee: certPreOwnedOptCampaign.attributes.acquisitionFee || 'N/A',
              certPreOwnedOpt_monthTerm: certPreOwnedOptCampaign.attributes.monthTerm || 'N/A',
              certPreOwnedOpt_msrp: certPreOwnedOptCampaign.attributes.msrp || 'N/A',
              certPreOwnedOpt_dmConquestCounts: certPreOwnedOptCampaign.counts.dmConquestCounts || 0,
              certPreOwnedOpt_dmCustomerCounts: 0,
              certPreOwnedOpt_emConquestCounts: certPreOwnedOptCampaign.counts.emailConquestCounts || 0,
              certPreOwnedOpt_emCustomerCounts: 0,
              certPreOwnedOpt_dmConquestCost: certPreOwnedOptCampaign.costs.biDm || 0,
              certPreOwnedOpt_dmCustomerCost: 0,
              certPreOwnedOpt_emConquestCost: certPreOwnedOptCampaign.costs.email || 0,
              certPreOwnedOpt_emCustomerCost: 0,
              certPreOwnedOpt_dmConquestTotal: totals.certPreOwnedOpt_dmConquestTotal || 0,
              certPreOwnedOpt_emConquestTotal: totals.certPreOwnedOpt_emConquestTotal || 0,
              certPreOwnedOpt_dmCustomerTotal: 0,
              certPreOwnedOpt_emCustomerTotal: 0,
              certPreOwnedOpt_total: totals.certPreOwnedOpt_total || 0,


              // Tax Advantage taxAdv
              taxAdv_nationalOffer: useNationalOffer || 'No',
              taxAdv_vehicle: (taxAdvCampaign.attributes.vehicle == undefined) ? 'N/A' : ipaceCampaign.attributes.vehicle,
              taxAdv_apr: taxAdvCampaign.attributes.apr || 'N/A',
              taxAdv_aprMonth: taxAdvCampaign.attributes.aprMonths || 'N/A',
              taxAdv_acquisitionFee: taxAdvCampaign.attributes.acquisitionFee || 'N/A',
              taxAdv_monthTerm: taxAdvCampaign.attributes.monthTerm || 'N/A',
              taxAdv_msrp: taxAdvCampaign.attributes.msrp || 'N/A',
              taxAdv_amountDown: taxAdvCampaign.attributes.amountDown || 'NA',
              taxAdv_amountPerMonth: taxAdvCampaign.attributes.amountPerMonth || 'NA',
              taxAdv_securityDeposit: taxAdvCampaign.attributes.securityDeposit || 'NA',
              taxAdv_dmConquestCounts: taxAdvCampaign.counts.dmConquestCounts || 0,
              taxAdv_dmCustomerCounts: taxAdvCampaign.counts.dmCustomerCounts || 0,
              taxAdv_emConquestCounts: taxAdvCampaign.counts.emailConquestCounts || 0,
              taxAdv_emCustomerCounts: taxAdvCampaign.counts.emailCustomerCounts || 0,
              taxAdv_postConquestCounts: taxAdvCampaign.counts.postConquestCounts || 0,
              taxAdv_postCustomerCounts: taxAdvCampaign.counts.postCustomerCounts || 0,
              taxAdv_dmConquestCost: taxAdvCampaign.costs.dm || 0,
              taxAdv_dmCustomerCost: taxAdvCampaign.costs.dm || 0,
              taxAdv_emConquestCost: taxAdvCampaign.costs.email || 0,
              taxAdv_emCustomerCost: taxAdvCampaign.costs.email || 0,
              taxAdv_postConquestCost: taxAdvCampaign.costs.post || 0,
              taxAdv_postCustomerCost: taxAdvCampaign.costs.post || 0,
              taxAdv_dmConquestTotal: totals.taxAdv_dmConquestTotal || 0,
              taxAdv_emConquestTotal: totals.taxAdv_emConquestTotal || 0,
              taxAdv_dmCustomerTotal: totals.taxAdv_dmCustomerTotal || 0,
              taxAdv_emCustomerTotal: totals.taxAdv_emCustomerTotal || 0,
              taxAdv_postConquestTotal: totals.taxAdv_postConquestTotal || 0,
              taxAdv_postCustomerTotal: totals.taxAdv_postCustomerTotal || 0,
              taxAdv_total: totals.taxAdv_total || 0,
              taxAdv_selected: (totals.taxAdv_total > 0) ? 'Yes' : 'No',

              orderTotal: ((orderTotal == undefined) ? 0.00 : orderTotal.toFixed(2))
            }
          };

      // set loading state
      this.setState({
        requestPending: true
      });

      // only send the lead whenever the user isn't
      // in a local, dev or staging environment
      if (
        !window.location.href.includes('ansira') &&
        !window.location.href.includes('localhost')
      ) {
        // post to lead endpoint
        axios
          .post(
            'https://cxixy6pb80.execute-api.us-west-2.amazonaws.com/prod/save/lead',
            {
              global_campaign: 'JLR Multi-campaign',
              campaign:
                this.props.theme === 'jaguar'
                  ? 'F-TYPE, Full Line, HSE, CPO'
                  : 'HSE, CPO',
              brand: this.props.theme,
              ...emailRequestBody.leadData,
              ...ftypeCampaign.hiddenFields,
              ...fullLineCampaign.hiddenFields,
              ...hseCampaign.hiddenFields
            }
          )
          .then(response => {
            console.log(response);
          });
        // post to email endpoint with the data
        this.setState({
          requestPending: true
        });
      }

      // send email
      axios
        .post(
          'https://cxixy6pb80.execute-api.us-west-2.amazonaws.com/prod/email/basic',
          emailRequestBody
        )
        .then(response => {
          if (response.status === 200) {
            this.setState({
              submissionSuccessful: true,
              requestPending: false
            });
          }
        });
    }
  };

  // allow the animation time to complete, then scroll to the
  // expanded accordion section
  scrollToElementAfterAnimation = () => {
    setTimeout(() => {
      const isEdge = window.navigator.userAgent.includes('Edge');
      const activeSection = document.querySelector(
        '.accordion-section.expanded'
      );
      if (isEdge) {
        window.scrollTo(0, activeSection.offsetTop);
      } else {
        window.scrollTo({
          top: activeSection.offsetTop,
          behavior: 'smooth'
        });
      }
    }, 400);
  };

  componentDidMount() {
    // this is to resolve a flash of unstyled content
    // when the page loads
    setTimeout(() => {
      document.querySelector('.page-loader').classList.add('hidden');
    }, 1010);
  }

  render() {
    return <div>{this.props.render(this.state)}</div>;
  }
}

export default StepsAccordion;
