import React from 'react';
import styled from 'styled-components';
import { colors, breakpoints } from '../../style-utilities/jagVariables';

const StypedSeparator = styled.hr`
  background: ${colors.textGray};
  margin-top: 40px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: -75px;
  }
`;

const Separator = () => {
  return <StypedSeparator />;
};

export default Separator;
