import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import lrFavicon from '../images/lr-favicon.ico';
// images
import slideOne from '../images/landrover/2019/preowned/18_01_667_LR_ACPO_GRMS_Hero_1150x580.jpg';
import lrdDiscovery from '../images/landrover/LRDiscoveryNational_Hero_1150x580.jpg'
import taxadvantage from '../images/landrover/2019/taxadvantage/19_01_799_LR_TaxAdv_RR_1150x580-5-.jpg';


// components
import Header from '../components/Header';
import Hero from '../components/Hero';
import CampaignDetails from '../components/CampaignDetails/CampaignDetails';
import CampaignBlock from '../components/CampaignDetails/CampaignBlock';
import Footer from '../components/Footer/Footer';
import StepsAccordion from '../components/StepsAccordion/StepsAccordion';
import AccordionSection from '../components/StepsAccordion/AccordionSection';
import CampaignOrderForm from '../components/CampaignOrderForm';
import Separator from '../components/StepsAccordion/Separator';
import Button from '../components/Button';
import OrderTotal from '../components/OrderTotal';
import ContactInformation from '../components/ContactInformation';
import SummaryAndSubmit from '../components/SummaryAndSubmit';
import DealerSelect from '../components/DealerSelect';
import CampaignBuilder from '../components/CampaignBuilder/CampaignBuilder';
// import campaigns
import availableCampaigns from '../static-data/lrCampaigns';
import Success from '../components/Success';

import Modal from '../components/Modal';

const StyledLandRoverHse = styled.div``;

const LandRoverHse = ({ data }) => {
  // set theme and number of campaigns for this page
  // used for theming in child components components
  // the accepted values (for now) are:
  // 'jaguar' or 'landrover'
  const theme = 'landrover';
  const beforeFilterDealers = data.allWordpressWpLrDealers.edges.sort((a, b) => {
    const dealerNumber = dealer =>
      parseInt(dealer.node.acf.dealer_code.substr(1));
      // const util = require('util');
      // console.log(util.inspect(a, false, null, true /* enable colors */));
    return dealerNumber(a) - dealerNumber(b);
  });

  const dealers = beforeFilterDealers.filter((dealer, index) => {
    // filters out dealers that have 0 customer and conquest counts

    let cpo_dm_customer;
    let cpo_dm_conquest;
    let cpo_em_customer;
    let cpo_em_conquest;

    let lr_2019tax_dm_conquest;
    let lr_2019tax_dm_customer;
    let lr_2019tax_em_conquest;
    let lr_2019tax_em_customer;
    let lr_2019tax_post_conquest;
    let lr_2019tax_post_customer;

    if (theme === 'jaguar') {
      cpo_dm_customer = dealer.node.acf.jag_cpo_dm_customer;
      cpo_dm_conquest = dealer.node.acf.jag_cpo_dm_conquest;
      cpo_em_customer = dealer.node.acf.jag_cpo_em_customer;
      cpo_em_conquest = dealer.node.acf.jag_cpo_em_conquest;
    } else {
      cpo_dm_customer = dealer.node.acf.lr_cpo_dm_customer;
      cpo_dm_conquest = dealer.node.acf.lr_cpo_dm_conquest;
      cpo_em_customer = dealer.node.acf.lr_cpo_em_customer;
      cpo_em_conquest = dealer.node.acf.lr_cpo_em_conquest;

      lr_2019tax_dm_conquest = dealer.node.acf.lr_2019tax_dm_conquest;
      lr_2019tax_dm_customer = dealer.node.acf.lr_2019tax_dm_customer;
      lr_2019tax_em_conquest = dealer.node.acf.lr_2019tax_em_conquest;
      lr_2019tax_em_customer = dealer.node.acf.lr_2019tax_em_customer;
      lr_2019tax_post_conquest = dealer.node.acf.lr_2019tax_post_conquest;
      lr_2019tax_post_customer = dealer.node.acf.lr_2019tax_post_customer;
    }

    if(cpo_dm_customer === null && cpo_dm_conquest === null && cpo_em_customer=== null && cpo_em_conquest === null) {
      console.log('dealer removed: '+ dealer.node.acf.dealer_code);
    } else {
      return dealer;
    }
  });

  const vehicles = data.allWordpressWpJlrVehicle.edges
    .map(({ node }) => {
      let vehicleOrder = ['Range Rover Evoque', 'Range Rover Sport', 'Discovery Sport', 'LR4', 'Range Rover', 'Discovery'];
      let assignedPosition = vehicleOrder.indexOf(node.acf.vehicle);
      return {
        slug: node.slug,
        displayName: `${node.acf.year} ${node.acf.vehicle}`,
        listPosition: assignedPosition,
        ...node.acf
      };
    })
    .sort((a, b) => a.year - b.year)
    .sort((a, b) => {
      const vehicleA = a.vehicle.toLowerCase();
      const vehicleB = b.vehicle.toLowerCase();

      return vehicleA.localeCompare(vehicleB);
    })
    .sort((a, b) => a.listPosition - b.listPosition);;
    

  //    let newformat = {};
  //    let toPrint = vehicles.map( vehicle => {
    
  //   newformat[vehicle.displayName] = {
  //     leaseOffer: '',
  //     monthTerm: vehicle.month_term,
  //     dueAtSigning: '',
  //     customerCredit: '',
  //     securityDeposit: '',
  //     apr: vehicle.apr,
  //     aprMonths: vehicle.apr_month,
  //     aprCustomerCredit: '',
  //     xxxxModels: '',
  //     acquisitionFee: '',
  //     msrp: '',
  //     totalOfLeasePayments: ''
  //   };
  //   return newformat;
  // });
  // console.log(JSON.stringify(newformat, null, 4));

    // console.log(dealers);

    // let filteredDealers = dealers.filter((dealer, index) => {
    //   // console.log(dealer.node.acf.jag_cpo_dm_conquest);
    //   // console.log(theme);

    //   let cpo_dm_customer;
    //   let cpo_dm_conquest;
    //   let cpo_em_customer;
    //   let cpo_em_conquest;

    //   if (theme === 'jaguar') {
    //     cpo_dm_customer = dealer.node.acf.jag_cpo_dm_customer;
    //     cpo_dm_conquest = dealer.node.acf.jag_cpo_dm_conquest;
    //     cpo_em_customer = dealer.node.acf.jag_cpo_em_customer;
    //     cpo_em_conquest = dealer.node.acf.jag_cpo_em_conquest;
    //   } else {
    //     cpo_dm_customer = dealer.node.acf.lr_cpo_dm_customer;
    //     cpo_dm_conquest = dealer.node.acf.lr_cpo_dm_conquest;
    //     cpo_em_customer = dealer.node.acf.lr_cpo_em_customer;
    //     cpo_em_conquest = dealer.node.acf.lr_cpo_em_conquest;
    //   }

    //   if(cpo_dm_customer === null && cpo_dm_conquest === null && cpo_em_customer=== null && cpo_em_conquest === null) {
    //     console.log('dealer removed: '+ dealer.node.acf.dealer_code);
    //     console.log('dmCustomer:' + cpo_dm_customer + 'emCustomer' + cpo_em_customer);
    //     console.log('dmCustomer:' + cpo_dm_conquest + 'emCustomer' + cpo_em_conquest);
    //   } else {
    //     return dealer;
    //   }
    // });  

  return (
    <StyledLandRoverHse data-theme={theme}>
      <Modal theme={theme} campaigns={availableCampaigns} />
      <Helmet>
        <title>Land Rover USA</title>
        <link rel="icon" type="image/png" href={lrFavicon} sizes="16x16" />
      </Helmet>
      <Header theme={theme} />
      <Hero images={[taxadvantage]} theme={theme} altText={['Range Rover']} />
      <CampaignDetails headline="Available Campaigns for Enrollment">
        {availableCampaigns.map(({ active, id, title, description, optionalFlag }) => {
          if (active) {
            return (
              <CampaignBlock
                key={id}
                headline={title}
                description={description}
                optionalFlag={optionalFlag}
              />
            );
          }
        })}
      </CampaignDetails>
      <StepsAccordion
        theme={theme}
        render={accordionState => {
          /**
           * creates a CampaignOrderForm component for each campaign
           * on the activeCampaigns object
           */
          const renderCampaignOrderForms = () =>
            Object.keys(accordionState.activeCampaigns)
              .sort(
                (a, b) =>
                  accordionState.activeCampaigns[a].order -
                  accordionState.activeCampaigns[b].order
              )
              .map((campaign, index, arr) => {
                const hasSeparator = arr.length > 1 && index + 1 !== arr.length;
                // if optional flag is set do not render
                if (accordionState.activeCampaigns[campaign].optionalFlag === true) {
                  
                } else {
                  return (
                    <React.Fragment key={campaign}>
                      <CampaignOrderForm
                        theme={theme}
                        campaign={accordionState.activeCampaigns[campaign]}
                        selectedDealer={accordionState.selectedDealer}
                        orderCounts={
                          accordionState.activeCampaigns[campaign].counts
                        }
                        updateOrderCounts={accordionState.updateOrderCounts}
                        updateOrderTotal={accordionState.updateOrderTotal}
                        handleSecondStep={accordionState.handleSecondStep}
                      />
                      {/* {hasSeparator && <Separator />} */}
                    </React.Fragment>
                  );
                }
              });

          // using render prop to use StepsAccordion state
          // and functionality while being able to reuse
          // component with different campaign pages
          return (
            <div>
              {!accordionState.submissionSuccessful && (
                <>
                  {/* accordion Step 1 */}
                  <AccordionSection
                    id={1}
                    title="Select Retailer & Campaign"
                    theme={theme}
                    activeStep={accordionState.activeStep}
                    validSteps={accordionState.validSteps}
                    editStep={accordionState.editStep}
                  >
                    <form
                      onSubmit={event => {
                        event.preventDefault();
                        accordionState.proceedToNextStep(1);
                      }}
                    >
                      <DealerSelect
                        theme={theme}
                        dealers={dealers}
                        selectedDealer={accordionState.selectedDealer}
                        handleDealerSelect={accordionState.handleDealerSelect}
                      />
                      {/* when a dealer is selected, render a CampaignBuilder
                    for each available campaign
                  */}
                      {accordionState.selectedDealer &&
                        availableCampaigns.map(campaign => (
                          <CampaignBuilder
                            vehicles={vehicles}
                            theme={theme}
                            key={campaign.id}
                            campaign={campaign}
                            selectCampaign={accordionState.selectCampaign}
                            activeCampaigns={accordionState.activeCampaigns}
                            updateCampaignAttr={
                              accordionState.updateCampaignAttr
                            }
                            setNationalOfferAttr={
                              accordionState.setNationalOfferAttr
                            }
                            setHiddenFields={accordionState.setHiddenFields}
                            copyFlag={campaign.optionalFlag}
                            expandCopy={accordionState.linkedCampaignExpand}
                            handleLinkedCampaign={accordionState.handleLinkedCampaign}
                          />
                        ))}
                      <div className="co-op">
                        <label htmlFor="coopFunds">
                          <input
                            type="checkbox"
                            name="coopFunds"
                            id="coopFunds"
                            style={{
                              display: 'inline-block',
                              marginRight: '10px',
                              marginBottom: '15px'
                            }}
                            value={accordionState.coopFunds}
                            onChange={accordionState.toggleCoopFunds}
                          />
                          Please submit for Land Rover Co-op funds
                        </label>
                      </div>
                      <Button
                        type="submit"
                        theme="dark"
                        text="Save & Continue"
                        disabled={
                          // make sure at least 1 campaign is selected before proceeding
                          Object.keys(accordionState.activeCampaigns).length ===
                          0
                        }
                        onClick={accordionState.handleOptionalCampaign}
                      />
                    </form>
                  </AccordionSection>
                  {/* accordion Step 2 */}
                  <AccordionSection
                    id={2}
                    title="Select Order Quantity"
                    theme={theme}
                    activeStep={accordionState.activeStep}
                    validSteps={accordionState.validSteps}
                    editStep={accordionState.editStep}
                  >
                    <form
                      onSubmit={event => {
                        event.preventDefault();
                        accordionState.proceedToNextStep(2);
                      }}
                    >
                      {renderCampaignOrderForms()}
                      <OrderTotal total={accordionState.orderTotal} />
                      <Button
                        disabled={!accordionState.secondStepCanProceed}
                        type="submit"
                        theme="dark"
                        text="Save & Continue"
                      />
                       {!accordionState.secondStepCanProceed ? (<div className="field-msg">This form requires additional values.</div>) : ('')}
                    </form>
                  </AccordionSection>
                  {/* accordion Step 3 */}
                  <AccordionSection
                    id={3}
                    title="Enter Contact Information"
                    theme={theme}
                    activeStep={accordionState.activeStep}
                    validSteps={accordionState.validSteps}
                    editStep={accordionState.editStep}
                  >
                    <form
                      onSubmit={event => {
                        event.preventDefault();
                        accordionState.proceedToNextStep(3);
                      }}
                    >
                      <ContactInformation
                        updateContactInfo={accordionState.updateContactInfo}
                        selectedDealer={accordionState.selectedDealer}
                        setHours={accordionState.setHours}
                      />
                      <Button
                        type="submit"
                        theme="dark"
                        text="Save & Continue"
                      />
                    </form>
                  </AccordionSection>
                  {/* accordion Step 4 */}
                  <AccordionSection
                    id={4}
                    title="Review & Submit Order"
                    theme={theme}
                    activeStep={accordionState.activeStep}
                    validSteps={accordionState.validSteps}
                  >
                    <form onSubmit={accordionState.submitOrder}>
                      <SummaryAndSubmit
                        theme={theme}
                        type="summary"
                        appState={accordionState}
                        editStep={accordionState.editStep}
                      />
                      {accordionState.requestPending && <div>sending...</div>}
                      <Button type="submit" theme="dark" text="Submit" />
                    </form>
                  </AccordionSection>
                </>
              )}
              {accordionState.submissionSuccessful && (
                <Success
                  email={accordionState.selectedDealer.email}
                  theme={theme}
                />
              )}
            </div>
          );
        }}
      />
      <div
        className="page-loader"
        style={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          background: 'white',
          top: '0',
          left: '0'
        }}
      />
      <Footer theme={theme} />
    </StyledLandRoverHse>
  );
};

export const query = graphql`
  query {
    allWordpressWpLrDealers {
      edges {
        node {
          slug
          title
          acf {
            dealer_code
            street
            town
            state
            post_code
            phone
            url
            lr_hse_dm_conquest_counts
            lr_hse_em_conquest_counts
            lr_cpo_dm_conquest
            lr_cpo_em_conquest
            lr_cpo_dm_customer
            lr_cpo_em_customer
            lr_2019tax_dm_conquest
            lr_2019tax_dm_customer
            lr_2019tax_em_conquest
            lr_2019tax_em_customer
            lr_2019tax_post_conquest
            lr_2019tax_post_customer
          }
        }
      }
    }
    allWordpressWpJlrVehicle {
      edges {
        node {
          slug
          acf {
            brand
            campaign
            year
            vehicle
            lease_offer
            month_term
            cash_due_at_signing
            customer_credit
            security_deposit
            apr
            apr_month
            apr_customer_credit
            xxxx_models
            acquisition_fee
            msrp
            total_of_lease_payments
          }
        }
      }
    }
  }
`;

export default LandRoverHse;
