export default {
  "2014 F-TYPE": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 F-TYPE S V6": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 LR4": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 Range Rover": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 Range Rover Evoque": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 Range Rover Sport": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 XF": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 XJ": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2014 XJL": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 Discovery Sport": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 F-TYPE": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 F-TYPE S V6": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 LR4": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 Range Rover": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 Range Rover Evoque": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 Range Rover Sport": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 XF": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 XJ": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2015 XJL": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 Discovery Sport": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 F-TYPE": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 F-TYPE S V6": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 LR4": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 Range Rover": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 Range Rover Evoque": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 Range Rover Sport": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 XF": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 XJ": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2016 XJL": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 Discovery": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 Discovery Sport": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 F-PACE": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 F-TYPE": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 F-TYPE S V6": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 Range Rover": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 Range Rover Evoque": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 Range Rover Sport": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 XE": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 XF": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 XJ": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2017 XJL": {
      leaseOffer: "",
      monthTerm: "1-24 months",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0.9%",
      aprMonths: null,
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 E-PACE S P250": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 F-PACE 25t Premium": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 F-TYPE Convertible 2.0L 296HP": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 F-TYPE Convertible 3.0L 340HP": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 F-TYPE Coupe 2.0L 296HP": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 F-TYPE Coupe 3.0L 340HP": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 XE 25t AWD": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  },
  "2019 XE 25t RWD": {
      leaseOffer: "",
      monthTerm: "36",
      dueAtSigning: "",
      customerCredit: "",
      securityDeposit: "",
      apr: "0",
      aprMonths: "24 to 60",
      aprCustomerCredit: "",
      xxxxModels: "",
      acquisitionFee: "",
      msrp: "",
      totalOfLeasePayments: ""
  }
};
