// images
import LRdmThumb from '../images/landrover/2019/taxadvantage/19_01_799_LR_TaxAdv_DM_300x300-4-_thumb.png';
import LRdmFull from '../images/landrover/2019/taxadvantage/19_01_799_LR_TaxAdv_DM_900x900_2.jpg';
import LRemThumb from '../images/landrover/2019/taxadvantage/19_01_799_LR_TaxAdv_Em1_300x300_thumb.png';
import LRemFull from '../images/landrover/2019/taxadvantage/19_01_799_LR_TaxAdv_Em_900x900_Combined.jpg';

import LRPostThumb from '../images/landrover/2019/taxadvantage/19_01_799_LR_TaxAdv_Postcard_300x300_thumb.png';
import LRPostFull from '../images/landrover/2019/taxadvantage/19_01_799_LR_TaxAdv_Postcard_900x900.jpg';

export default [
//   {
//     order: 1,
//   active: true,
//   id: '2019-pre-owned-3',
//   type: 'Approved Certified Pre-Owned Sales Event',
//   campaignMatch: 'landrover_cpo',
//   title: 'Land Rover Approved Certified Pre-Owned Sales Event',
//   title2: 'Approved Certified Pre-Owned Sales Event',
//   hasClimateSelect: false,
//   description: 'The Land Rover Approved Certified Pre-Owned Sales Event is a 3-month campaign that is localized for your retailer. Please choose two vehicles and model years to focus on, depending on the inventory you want to sell. This campaign highlights the attractive 0.9% APR National Sales Offer for up to 24 months. The conquests are luxury SUV owners of competitive vehicles in market in your AOR. The customer lists are provided by JLRNA and include current owners, disposers and owners of used vehicles in your AOR. This data-driven conquest campaign comes with one direct mail piece and two emails. This campaign is 100% co-op eligible, and no submission is necessary.',
//   costs: {
//     dm: 1.46,
//     email: 0.13
//   },
//   images: {
//     dmThumb: LRdmThumb,
//     dmFull: LRdmFull,
//     emThumb: LRemThumb,
//     emFull: LRemFull
//   },
//   optionalFlag: false,
//   attributes: {
//   }
// },
  // {
  //   order: 1,
  //   active: true,
  //   id: '2019-tax-advantage',
  //   type: 'Tax Advantage',
  //   campaignMatch: 'landrover_cpo',
  //   title: 'Land Rover Tax Advantage Campaign 2019',
  //   title2: 'Land Rover Tax Advantage Campaign 2019',
  //   hasClimateSelect: false,
  //   description: 'The Land Rover “Tax Advantage” campaign encourages business owner customers to purchase select Land Rover vehicles eligible for a tax credit. This is a customer and prospect campaign with two available direct mail options and two email follow-ups. Sign-ups are from 9/4 to 9/27. Deployment of Direct Mail and Email will go from early October to mid-November. Results reporting by retailer available from JLRNA.',
  //   costs: {
  //     dm: 1.25,
  //     email: 0.11,
  //     post: 0.55
  //   },
  //   images: {
  //     dmThumb: LRdmThumb,
  //     dmFull: LRdmFull,
  //     postThumb: LRPostThumb,
  //     postFull: LRPostFull,
  //     emThumb: LRemThumb,
  //     emFull: LRemFull,
  //   },
  //   optionalFlag: false,
  //   attributes: {
  //   }
  // },
  // {
  //   order: 2,
  //   active: true,
  //   attributes: {
  //     acquisitionFee: "",
  //     apr: "", 
  //     aprCustomerCredit: "",
  //     aprMonths: "",
  //     customerCredit: "",
  //     dueAtSigning: "",
  //     leaseOffer: "", 
  //     monthTerm: "",
  //     msrp: "",
  //     securityDeposit: "",
  //     totalOfLeasePayments: "",
  //     vehicle: "",
  //     xxxxModels: ""
  //   },
  //   id: '2019-pre-owned-2',
  //   type: 'Approved Certified Pre-Owned Sales Event',
  //   campaignMatch: 'landrover_cpo',
  //   title: 'Land Rover Approved Certified Pre-Owned Sales Event',
  //   title2: 'Approved Certified Pre-Owned Sales Event',
  //   hasClimateSelect: false,
  //   description: 'The Land Rover Approved Certified Pre-Owned Sales Event is a 3-month campaign that is localized for your retailer. Please choose two vehicles and model years to focus on, depending on the inventory you want to sell. This campaign highlights the attractive 0.9% APR National Sales Offer for up to 24 months. The conquests are luxury SUV owners of competitive vehicles in market in your AOR. The customer lists are provided by JLRNA and include current owners, disposers and owners of used vehicles in your AOR. This data-driven conquest campaign comes with one direct mail piece and two emails. This campaign is 100% co-op eligible, and no submission is necessary.',
  //   costs: {
  //     dm: 1.46,
  //     email: 0.13
  //   },
  //   images: {
  //     dmThumb: LRdmThumb,
  //     dmFull: LRdmFull,
  //     emThumb: LRemThumb,
  //     emFull: LRemFull
  //   },
  //   optionalFlag: true,
  //   attributes: {
  //   }
  // }
];
