import React from 'react';
import styled from 'styled-components';
import { colors as lrColors } from '../style-utilities/lrVariables';
import { colors as jagColors } from '../style-utilities/jagVariables';
// image
import checkMark from '../images/circle-check-mark-png@2x.png';
// components
import Button from '../components/Button';

const StyledSuccess = styled.div`
  color: white;
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;

  &.landrover {
    background: ${lrColors.gray1};
  }

  &.jaguar {
    background: ${jagColors.gray1};
  }

  img {
    height: 145px;
    object-fit: cover;
    margin: 0 auto;
    margin-bottom: 45px;
    width: 145px;
  }

  h2 {
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  p {
    font-size: 18px;
    font-weight: lighter;
  }

  hr {
    background: white;
    max-width: 75%;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
  }
`;

const Success = ({ theme, email }) => {
  return (
    <StyledSuccess className={`${theme}`}>
      <img src={checkMark} alt="Success" />
      <h2>Order Submitted Successfully!</h2>
      <p>
        Thank you for your order. A confirmation email has been sent to {email}
      </p>
      <hr />
      <p>Would you like to submit another order?</p>
      <Button
        theme="dark"
        text="Order Now"
        onClick={() => window.location.reload()}
      />
    </StyledSuccess>
  );
};

export default Success;
