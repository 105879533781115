import React from 'react';
import styled from 'styled-components';
import {
  colors as jagColors,
  breakpoints
} from '../../style-utilities/jagVariables';
import { colors as lrColors } from '../../style-utilities/lrVariables';
// images
import upArrow from '../../images/jaguar/up-arrow-png.png';

const StyledAccordionSection = styled.div`
  color: white;

  &.jaguar {
    &.section-1 {
      background: ${jagColors.gray1};
    }
    &.section-2 {
      background: ${jagColors.gray2};
    }
    &.section-3 {
      background: ${jagColors.gray3};
    }
    &.section-4 {
      background: ${jagColors.gray4};
    }
    &.section-5 {
      background: ${jagColors.gray5};
    }
  }

  &.landrover {
    &.section-1 {
      background: ${lrColors.gray1};
    }
    &.section-2 {
      background: ${lrColors.gray2};
    }
    &.section-3 {
      background: ${lrColors.gray3};
    }
    &.section-4 {
      background: ${lrColors.gray4};
    }
    &.section-5 {
      background: ${lrColors.gray5};
    }
  }

  .accordion-header {
    align-items: center;
    color: white;
    display: flex;
    justify-content: space-evenly;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }
  }

  .number {
    display: inline-block;
    font-size: 80px;
    font-weight: 900;
    width: 60px;
  }

  .title {
    flex-grow: 2;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 3px;
    width: 200px;
  }

  .edit {
    display: none;
    background: transparent;
    border: none;
    color: white;
    font-size: 22px;
    font-weight: 100;
    text-decoration: underline;
  }

  .arrow {
    display: none;
    margin-bottom: 0;
    margin-left: 15px;
    transition: 0.4s all ease;
  }

  .accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: 0.2s all ease;
  }

  &.expanded {
    .accordion-header {
      border-bottom: 1px solid white;
    }

    .accordion-body {
      padding-bottom: 25px;
      padding-top: 25px;
      max-height: 4000px;
      transition: 0.2 all ease;
    }

    .arrow {
      transform: rotate(180deg);
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .number {
      width: 75px;
    }

    .title {
      font-size: 24px;
    }

    .edit {
      display: block;
    }

    .arrow {
      display: block;
    }

    .accordion-body {
      padding-left: 75px;
    }
  }
`;

/**
 * This is a dynamic component which accepts props specific to this section
 * for titling, functionality, etc
 *
 * You can place whatever content you like within the component, and extend it with
 * methods and props as necessary
 *
 * @param {Object} props
 */
const AccordionSection = props => {
  const isActive = props.activeStep === props.id;
  // const isActive = true;
  const canEdit =
    !isActive && props.id !== 4 && props.validSteps.includes(props.id);

  return (
    <StyledAccordionSection
      className={`accordion-section ${isActive ? 'expanded' : ''} section-${
        props.id
      } ${props.theme}`}
      onClick={canEdit ? () => props.editStep(props.id) : () => {}}
    >
      <div className={`page-container accordion-header`}>
        <span className="number">{props.id}</span>
        <span className="title">{props.title}</span>
        {canEdit && <button className="edit">Edit</button>}
        <img className="arrow" src={upArrow} alt="" />
      </div>
      <div className="accordion-body">
        <div className="page-container">{props.children}</div>
      </div>
    </StyledAccordionSection>
  );
};

export default AccordionSection;
