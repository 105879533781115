import styled from 'styled-components';
import {
  colors as jagColors,
  breakpoints
} from '../../style-utilities/jagVariables';
import { colors as lrColors } from '../../style-utilities/lrVariables';

export default styled.div`
  margin-bottom: 15px;
  max-width: 100%;
  small {
    /* display: none; */
  }
  .small-show {
    display: block;
  }
  .menu-header {
    label {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    &.landrover {
      background: ${lrColors.gray2};
    }

    &.jaguar {
      background: ${jagColors.gray2};
    }
  }

  .menu-body {
    background: ${jagColors.gray5};
    color: black;
    max-height: 0;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    transition: 0.4s all ease;
    margin-bottom: 7.2px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    select {
      background: ${jagColors.inputGray};
      border: 1px solid ${jagColors.gray3};
      letter-spacing: 2px;
      padding: 12px;
      width: 100%;
    }

    select {
      height: 40px;
      padding: 0 5px;
    }

    .model-year-type-select {
      margin-top: 15px;
    }

    .attributes {
      display: grid;
      grid-gap: 15px;
      margin-bottom: 20px;
      margin-top: 25px;

      @media (max-width: ${breakpoints.mobileMax}) {
        > * {
          grid-column: span 2;
        }
      }

      input {
        border: 1px solid ${jagColors.gray4};
      }
    }

    .field-separator {
      grid-column: span 2;
      margin-top: 0px;
    }

    .national-offer {
      grid-column: span 2;
    }

    .vehicle-type {
      grid-column: span 2;
    }

    &.expanded {
      max-height: 1200px;
      transition: 0.4s all ease;
    }
  }

  .customer-credit-disclaimer {
    @media (min-width: 592px) and (max-width: 767px) {
      bottom: -4px;
      margin-left: 15px;
      position: relative;
    }
  }
  .removeDropdown {
    -moz-appearance: window;
    -webkit-appearance: none;
  }
  @media (min-width: ${breakpoints.tablet}) {
    max-width: 50%;

    .attributes {
      grid-template-columns: 1fr 1fr;
    }
  }
`;
