import React, { Component } from 'react';

class CampaignIpace extends Component {
  render() {
    // Destructure props
    // state props
    const {
      campaign,
      expanded,
      isActive,
      activeCampaigns,
      vehicleTypes,
      useNationalOffer
    } = this.props;

    // function props
    const { handleInputChange } = this.props;

    const campaignAttrs =
      activeCampaigns[campaign.id] && activeCampaigns[campaign.id].attributes
        ? activeCampaigns[campaign.id].attributes
        : {};

    return (
      <div className={`menu-body ${expanded ? 'expanded' : ''}`}>
        <div className="attributes">
          {/* start national offer */}
          <div className="national-offer">
            <small className="small-show">
              Please select either the National offer or create your own.
            </small>
            <label htmlFor="nationalOffer">
              Would you like to select the National offer?{' '}
            </label>
            <select
              required={isActive}
              id="nationalOffer"
              ref="nationalOffer"
              name="nationalOffer"
              onChange={event => {
                handleInputChange(event);
              }}
            >
              <option defaultValue value="">
                Select an option
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <hr className="field-separator" />
          {/* end national offer */}

          {/* start vehicle-type */}
          <div className="input-group">
            <label htmlFor="vehicleType"> Vehicle and Model Year </label>
            <select
              ref="vehicle"
              required={true}
              id="vehicle"
              name="vehicle"
              onChange={handleInputChange}
              disabled={true}
              className="removeDropdown"
            >
              {/* <option defaultValue value="">
                            Select Vehicle
                        </option> */}
              {/* <option key="2019-i-pace-s" defaultValue value="2019 I-PACE S">
                            2019 I-PACE S
                        </option> */}
              {/* find the corresponding array of vehicle types based on campaign
                            render an option for each one
                        */}
              {vehicleTypes.map(vehicle => {
                return (
                  <option
                    key={vehicle.slug}
                    defaultValue
                    value={vehicle.displayName}
                  >
                    {vehicle.displayName}
                  </option>
                );
              })}
            </select>
          </div>
          {/* end vehicle-type */}
          {/* start input-groups */}
          <div className="input-group">
            <label htmlFor="">
              Offer Amount per month <small>(ex: $899)</small>
            </label>
            <input
              disabled={useNationalOffer}
              required={isActive}
              type="text"
              name="amountPerMonth"
              value={campaignAttrs['amountPerMonth'] || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">
              Months <small>(ex: 36)</small>
            </label>
            <input
              disabled={useNationalOffer}
              required={isActive}
              type="number"
              max="99"
              name="monthTerm"
              value={campaignAttrs['monthTerm'] || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">
              Amount Down <small>(ex: $5,995)</small>
            </label>
            <input
              disabled={useNationalOffer}
              required={isActive}
              type="text"
              name="amountDown"
              value={campaignAttrs['amountDown'] || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="">
              Security Deposit <small>(ex: $0)</small>
            </label>
            <input
              disabled={useNationalOffer}
              required={isActive}
              type="text"
              name="securityDeposit"
              value={campaignAttrs['securityDeposit'] || ''}
              onChange={handleInputChange}
            />
          </div>
          {/* end input-groups */}
        </div>
      </div>
    );
  }
}

export default CampaignIpace;
