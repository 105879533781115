import React from 'react';
import styled from 'styled-components';
import { colors } from '../style-utilities/jagVariables';

const StyledButton = styled.button`
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0;
  padding: 15px 40px;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Button = ({ theme, text, disabled, onClick, step, type }) => {
  return (
    <StyledButton
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled}
      style={{
        backgroundColor: theme === 'dark' ? colors.primary : colors.gray2
      }}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
