import React from 'react';
import styled from 'styled-components';
import { colors, breakpoints } from '../style-utilities/jagVariables';

const StyledOrderTotal = styled.div`
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
  padding-bottom: 30px;
  padding-top: 30px;

  span {
    display: inline-block;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .label {
    color: ${colors.textGray};
  }

  @media (min-width: ${breakpoints.tablet}) {
    justify-content: flex-end;
    margin-left: -75px;
    padding-bottom: 40px;
    padding-top: 40px;

    span {
      font-size: 22px;
    }

    .amount {
      margin-left: 150px;
    }
  }
`;

const OrderTotal = ({ total = 0 }) => {
  return (
    <StyledOrderTotal>
      <span className="label">Order Total</span>
      <span className="amount">${total.toFixed(2)}</span>
    </StyledOrderTotal>
  );
};
export default OrderTotal;
