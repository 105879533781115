import React from 'react';
import styled from 'styled-components';

const StyledFooterSocial = styled.ul`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-left: 0;

  li {
    list-style: none;
    margin-right: 5px;
  }

  svg {
    transition: .2s all ease;

    &:hover {
      transition: .2s all ease;
    }
  }

  svg.facebook-logo:hover {
    fill: #305891;
  }

  svg.twitter-logo:hover {
    fill: #2ca8d2;
  }

  svg.youtube-logo:hover {
    fill: #e62d27;
  }

  svg.google-plus-logo:hover {
    fill: #dd4b39;
  }

  svg.instagram-logo:hover {
    fill: #b51f59;
  }
`;

const FooterSocial = ({ theme }) => {
  return theme === 'jaguar' ? (
    <StyledFooterSocial>
      <li>
        <a
          href="https://www.facebook.com/JaguarUSA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="facebook-logo"
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
          >
            <path d="M0 0v24h24v-24h-24zm16 7h-1.923c-.616 0-1.077.252-1.077.889v1.111h3l-.239 3h-2.761v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/JaguarUSA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            className="twitter-logo"
          >
            <path d="M0 0v24h24v-24h-24zm18.862 9.237c.208 4.617-3.235 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.079-4.03 3.198-4.03.944 0 1.797.398 2.396 1.037.748-.147 1.451-.42 2.085-.796-.245.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.44.656-.997 1.234-1.638 1.697z" />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/user/jaguarusa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            className="youtube-logo"
          >
            <path d="M13.888 14.154c-.288 0-.535.184-.717.405v-1.893h-.814v5.88h.814v-.333c.225.28.471.388.766.388.537 0 .737-.417.737-.954v-2.406c0-.588-.195-1.087-.786-1.087zm-.044 3.376c0 .138-.023.238-.07.299-.089.116-.281.111-.413.045l-.191-.153v-2.703l.167-.132c.141-.071.323-.058.421.071.058.076.086.187.086.333v2.24zm-2.716-8.53v-2.119c0-.25.115-.434.353-.434.26 0 .371.179.371.434v2.119c0 .248-.126.431-.354.431-.235 0-.37-.191-.37-.431zm-1.849 4.52h-.94v5.027h-.908v-5.027h-.94v-.854h2.788v.854zm1.484.688h.807v4.338h-.807v-.479c-.148.176-.303.311-.464.403-.435.249-1.031.244-1.031-.636v-3.626h.806v3.325c0 .175.042.292.215.292.157 0 .376-.202.474-.325v-3.292zm-10.763-14.208v24h24v-24h-24zm13.434 5.62h.847v3.491c0 .184.044.307.226.307.165 0 .395-.212.498-.341v-3.457h.847v4.555h-.847v-.504c-.367.436-.723.569-.979.569-.454 0-.591-.364-.591-.813v-3.807zm-1.937-.038c.687 0 1.217.511 1.217 1.224v2.233c0 .765-.536 1.22-1.217 1.22-.821 0-1.241-.417-1.241-1.22v-2.233c0-.731.495-1.224 1.241-1.224zm-3.435-1.582l.658 2.468.611-2.468h.969l-1.11 3.67v2.504h-.954v-2.504l-1.134-3.67h.96zm8.493 15.912c-1.735.118-7.377.118-9.11 0-1.878-.128-2.097-1.267-2.111-4.245.014-2.983.235-4.116 2.111-4.245 1.733-.118 7.375-.118 9.11 0 1.878.128 2.097 1.267 2.111 4.245-.014 2.983-.235 4.116-2.111 4.245zm-.047-5.813c-.728 0-1.225.522-1.225 1.275v1.947c0 .781.394 1.342 1.164 1.342.85 0 1.208-.506 1.208-1.342v-.326h-.83v.301c0 .378-.02.607-.36.607-.325 0-.352-.282-.352-.607v-.818h1.542v-1.104c0-.783-.373-1.275-1.147-1.275zm.317 1.707h-.712v-.439c0-.298.062-.508.36-.508.29 0 .352.215.352.508v.439z" />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/jaguarusa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" className="instagram-logo"><path d="M14.667 12c0 1.473-1.194 2.667-2.667 2.667-1.473 0-2.667-1.193-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667zm3.846-3.232c.038.843.046 1.096.046 3.232s-.008 2.389-.046 3.233c-.1 2.15-1.109 3.181-3.279 3.279-.844.038-1.097.047-3.234.047-2.136 0-2.39-.008-3.232-.046-2.174-.099-3.181-1.132-3.279-3.279-.039-.845-.048-1.098-.048-3.234s.009-2.389.047-3.232c.099-2.152 1.109-3.181 3.279-3.279.844-.039 1.097-.047 3.233-.047s2.39.008 3.233.046c2.168.099 3.18 1.128 3.28 3.28zm-2.405 3.232c0-2.269-1.84-4.108-4.108-4.108-2.269 0-4.108 1.839-4.108 4.108 0 2.269 1.84 4.108 4.108 4.108 2.269 0 4.108-1.839 4.108-4.108zm1.122-4.27c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96c.531 0 .96-.43.96-.96zm6.77-7.73v24h-24v-24h24zm-4 12c0-2.172-.009-2.445-.048-3.298-.131-2.902-1.745-4.52-4.653-4.653-.854-.04-1.126-.049-3.299-.049s-2.444.009-3.298.048c-2.906.133-4.52 1.745-4.654 4.653-.039.854-.048 1.127-.048 3.299 0 2.173.009 2.445.048 3.298.134 2.906 1.746 4.521 4.654 4.654.854.039 1.125.048 3.298.048s2.445-.009 3.299-.048c2.902-.133 4.522-1.745 4.653-4.654.039-.853.048-1.125.048-3.298z"/></svg>
        </a>
      </li>
    </StyledFooterSocial>
  ) : (
    <StyledFooterSocial>
      <li>
        <a
          href="https://www.facebook.com/landroverusa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="facebook-logo"
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
          >
            <path d="M0 0v24h24v-24h-24zm16 7h-1.923c-.616 0-1.077.252-1.077.889v1.111h3l-.239 3h-2.761v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/LandRoverusa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            className="twitter-logo"
          >
            <path d="M0 0v24h24v-24h-24zm18.862 9.237c.208 4.617-3.235 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.079-4.03 3.198-4.03.944 0 1.797.398 2.396 1.037.748-.147 1.451-.42 2.085-.796-.245.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.44.656-.997 1.234-1.638 1.697z" />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/user/LandRoverusa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            className="youtube-logo"
          >
            <path d="M13.888 14.154c-.288 0-.535.184-.717.405v-1.893h-.814v5.88h.814v-.333c.225.28.471.388.766.388.537 0 .737-.417.737-.954v-2.406c0-.588-.195-1.087-.786-1.087zm-.044 3.376c0 .138-.023.238-.07.299-.089.116-.281.111-.413.045l-.191-.153v-2.703l.167-.132c.141-.071.323-.058.421.071.058.076.086.187.086.333v2.24zm-2.716-8.53v-2.119c0-.25.115-.434.353-.434.26 0 .371.179.371.434v2.119c0 .248-.126.431-.354.431-.235 0-.37-.191-.37-.431zm-1.849 4.52h-.94v5.027h-.908v-5.027h-.94v-.854h2.788v.854zm1.484.688h.807v4.338h-.807v-.479c-.148.176-.303.311-.464.403-.435.249-1.031.244-1.031-.636v-3.626h.806v3.325c0 .175.042.292.215.292.157 0 .376-.202.474-.325v-3.292zm-10.763-14.208v24h24v-24h-24zm13.434 5.62h.847v3.491c0 .184.044.307.226.307.165 0 .395-.212.498-.341v-3.457h.847v4.555h-.847v-.504c-.367.436-.723.569-.979.569-.454 0-.591-.364-.591-.813v-3.807zm-1.937-.038c.687 0 1.217.511 1.217 1.224v2.233c0 .765-.536 1.22-1.217 1.22-.821 0-1.241-.417-1.241-1.22v-2.233c0-.731.495-1.224 1.241-1.224zm-3.435-1.582l.658 2.468.611-2.468h.969l-1.11 3.67v2.504h-.954v-2.504l-1.134-3.67h.96zm8.493 15.912c-1.735.118-7.377.118-9.11 0-1.878-.128-2.097-1.267-2.111-4.245.014-2.983.235-4.116 2.111-4.245 1.733-.118 7.375-.118 9.11 0 1.878.128 2.097 1.267 2.111 4.245-.014 2.983-.235 4.116-2.111 4.245zm-.047-5.813c-.728 0-1.225.522-1.225 1.275v1.947c0 .781.394 1.342 1.164 1.342.85 0 1.208-.506 1.208-1.342v-.326h-.83v.301c0 .378-.02.607-.36.607-.325 0-.352-.282-.352-.607v-.818h1.542v-1.104c0-.783-.373-1.275-1.147-1.275zm.317 1.707h-.712v-.439c0-.298.062-.508.36-.508.29 0 .352.215.352.508v.439z" />
          </svg>
        </a>
      </li>

      <li>
        <a
          href="https://www.instagram.com/landroverusa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" className="instagram-logo"><path d="M14.667 12c0 1.473-1.194 2.667-2.667 2.667-1.473 0-2.667-1.193-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667zm3.846-3.232c.038.843.046 1.096.046 3.232s-.008 2.389-.046 3.233c-.1 2.15-1.109 3.181-3.279 3.279-.844.038-1.097.047-3.234.047-2.136 0-2.39-.008-3.232-.046-2.174-.099-3.181-1.132-3.279-3.279-.039-.845-.048-1.098-.048-3.234s.009-2.389.047-3.232c.099-2.152 1.109-3.181 3.279-3.279.844-.039 1.097-.047 3.233-.047s2.39.008 3.233.046c2.168.099 3.18 1.128 3.28 3.28zm-2.405 3.232c0-2.269-1.84-4.108-4.108-4.108-2.269 0-4.108 1.839-4.108 4.108 0 2.269 1.84 4.108 4.108 4.108 2.269 0 4.108-1.839 4.108-4.108zm1.122-4.27c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96c.531 0 .96-.43.96-.96zm6.77-7.73v24h-24v-24h24zm-4 12c0-2.172-.009-2.445-.048-3.298-.131-2.902-1.745-4.52-4.653-4.653-.854-.04-1.126-.049-3.299-.049s-2.444.009-3.298.048c-2.906.133-4.52 1.745-4.654 4.653-.039.854-.048 1.127-.048 3.299 0 2.173.009 2.445.048 3.298.134 2.906 1.746 4.521 4.654 4.654.854.039 1.125.048 3.298.048s2.445-.009 3.299-.048c2.902-.133 4.522-1.745 4.653-4.654.039-.853.048-1.125.048-3.298z"/></svg>
        </a>
      </li>
    </StyledFooterSocial>
  );
};

export default FooterSocial;
