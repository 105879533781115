import styled from 'styled-components';
import { colors, breakpoints } from '../../style-utilities/jagVariables';

export default styled.div`
  .order-form {
    background-color: ${colors.gray1};
    min-height: 170px;

    &:first-of-type {
      margin-bottom: 15px;
      margin-top: 35px;
    }

    &:nth-of-type(2) {
      margin-bottom: 15px;
    }

    .description {
      border-bottom: 2px solid ${colors.gray2};
      display: flex;

      .thumbnail {
        align-items: center;
        display: flex;
        margin-right: 3%;
        padding: 15px;
        width: 43%;

        img {
          max-width: 100%;
          margin-bottom: 0;
          width: 100%;
        }
      }

      .copy {
        width: 50%;

        h4 {
          font-size: 20px;
          letter-spacing: 2px;
          margin-bottom: 10px;
          margin-top: 20px;
          text-transform: uppercase;
        }

        ul {
          margin-bottom: 25px;
        }

        li {
          font-size: 0.85em;
        }
      }
    }

    .counts {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-transform: uppercase;

      .requiredField {
          position: absolute; 
          top: -200vh;
        }

      .row {
        align-items: center;
        display: flex;
        height: 50%;
        justify-content: flex-start;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;

        p {
          font-size: 12px;
          letter-spacing: 1px;
          margin-bottom: 0;
          width: 50%;
        }

        input {
          height: 28px;
          width: 25%;
          padding-left: 5px;
          position: relative;
          z-index: 1;
          &:disabled {
            opacity: 0.4;
          }
        }

        .amount {
          width: 25%;
          text-align: right;
          @media (min-width: ${breakpoints.mobileMax}) {
            padding-left: 40px;
            text-align: left;
          }
        }
      }
    }
  }

  .total-row {
    display: flex;
    flex-direction: column-reverse;
  }

  .note {
    line-height: 20px;
    padding-right: 8%;
    margin-top: 15px;
    padding-bottom: 25px;
    width: 100%;
  }

  .order-total {
    align-items: center;
    background: ${colors.gray1};
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 15px;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
  }

  .total-label {
    color: ${colors.textGray};
    font-size: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .total-amount {
    font-size: 18px;
    font-weight: bold;
  }

  .row {
    position: relative;
    .field-msg {
      position: absolute;
      background-color: #4a4a4f;
      left: 50%;
      top: 82%;
      padding: 5px 10px;
      text-transform: none;
    }
    .field-msg:before {
      content:"";
      position: absolute;
      height: 0px;
      width: 0px;
      top: -28px;
      left: 5px; 
      border-width: 15px;
      border-color: transparent transparent #4a4a4f transparent;
      border-style: solid; 
      z-index: 0;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .order-form {
      display: flex;
      margin-left: -75px;

      &:first-of-type {
        margin-bottom: 15px;
        margin-top: 75px;
      }

      .description,
      .counts {
        width: 50%;
      }

      .description {
        border-bottom: none;
      }

      .counts .row:first-of-type {
        border-bottom: 1px solid ${colors.gray2};
      }

      .description {
        border-right: 1px solid ${colors.gray2};

        .thumbnail {
          margin-right: 6%;
          width: 24%;
        }
      }
    }

    .total-row {
      flex-direction: row;
      margin-left: -75px;

      > * {
        width: 50%;
      }
    }
  }
`;
