import React from 'react';
import styled from 'styled-components';
// variables
import { breakpoints } from '../style-utilities/jagVariables';
// components
import ImageSlider from './ImageSlider';
import TitleSection from './TitleSection';

const StyledHero = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    flex-wrap: nowrap;
    height: 400px;
  }
`;

const Hero = ({ images, theme, altText }) => {
  return (
    <StyledHero>
      <TitleSection theme={theme} />
      <ImageSlider images={images} altText={altText} />
    </StyledHero>
  );
};

export default Hero;
