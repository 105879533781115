import React from 'react';
import styled from 'styled-components';
import {
  colors as jagColors,
  breakpoints
} from '../style-utilities/jagVariables';
import LinkedLogo from './LinkedLogo';

const StyledHeader = styled.header`
  padding-bottom: 10px;
  padding-top: 10px;

  .page-container {
    display: flex;
  }

  .page-container {
    justify-content: flex-start;
  }

  &.jaguar {
    background: ${jagColors.primary};
  }
`;

const Header = ({ theme }) => (
  <StyledHeader className={`${theme}`}>
    <div className="page-container">
      <LinkedLogo theme={theme} />
    </div>
  </StyledHeader>
);

export default Header;
