import React, { Component } from 'react';
import styled from 'styled-components';

import OrderTotal from './OrderTotal';
import Separator from './StepsAccordion/Separator';
import { breakpoints } from '../style-utilities/jagVariables';
import SummaryBlock from './SummaryBlock';
import EditButton from './EditButton';

const StyledContact = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 30px;
  @media (max-width: ${breakpoints.mobileMax}) {
    display: block !important;
  }
`;

const StyledCol = styled.div`
  text-align: left;
  color: #fff;
  p {
    text-transform: uppercase;
    font-weight: 200 !important;
    line-height: 1.4;
    margin-bottom: 5px !important;
  }
  p span {
    text-transform: uppercase;
  }
  .OperationHour {
    text-transform: uppercase;
  }
  @media (max-width: ${breakpoints.mobileMax}) {
    h3 {
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
`;

class SummaryAndSubmit extends Component {
  /**
   * creates a SummaryBlock component for each campaign
   * on the activeCampaigns object
   */
  renderSummaryBlocks = () => {
    const { activeCampaigns, subTotals } = this.props.appState;

    return Object.keys(activeCampaigns)
      .sort((a, b) => activeCampaigns[a].order - activeCampaigns[b].order)
      .map((campaign, index, arr) => {
        const hasSeparator = arr.length > 1 && index + 2 !== arr.length;
        if (activeCampaigns[campaign].optionalFlag === true) {
          
        } else {
          return (
            <React.Fragment key={campaign}>
              <SummaryBlock
                theme={this.props.theme}
                editStep={this.props.editStep}
                campaign={activeCampaigns[campaign]}
                orderCounts={activeCampaigns[campaign].counts}
                subTotals={subTotals}
              />
              {hasSeparator && <Separator />}
            </React.Fragment>
          );
        }
      });
  };

  render() {
    const {
      selectedDealer,
      activeCampaigns,
      coopFunds,
      orderTotal,
      useNationalOffer
    } = this.props.appState;

    return selectedDealer ? (
      <div>
        <h3>
          Retailer & Campaign(s)
          <EditButton onClick={() => this.props.editStep(1)}>Edit</EditButton>
        </h3>

        {selectedDealer && <p>Retailer: {selectedDealer.name ? selectedDealer.name.replace('&#8217;', "'") : ''}</p>}

        <p>Campaign(s):</p>

        {/* iterate activeCampaigns and list the names */}
        {Object.keys(activeCampaigns).map(campaign => {
          const campaignObject = activeCampaigns[campaign];
          if (campaignObject.optionalFlag === true) {

          } else {
            return (
              <div key={campaign}>
                <h4>{campaignObject.title}</h4>
                {/* Vehicle Data is removed for land rover 2019 so this block is commented out 
                <ul>
                  {campaignObject.attributes && (
                    <li>Vehicle: {campaignObject.attributes.vehicle}</li>
                  )}
                  {campaignObject.climate && (
                    <li>Climate: {campaignObject.climate}</li>
                  )}
                  {campaignObject.type === 'Holiday Sales Event' && (
                    <li>National Offer: {useNationalOffer ? 'Yes' : 'No'}</li>
                  )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.leaseOffer && (
                      <li>Lease Offer: {campaignObject.attributes.leaseOffer}</li>
                    )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.monthTerm && (
                      <li>Month Term: {campaignObject.attributes.monthTerm}</li>
                    )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.dueAtSigning && (
                      <li>
                        Cash Due at Signing:{' '}
                        {campaignObject.attributes.dueAtSigning}
                      </li>
                    )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.customerCredit && (
                      <li>
                        Customer Credit:{' '}
                        {campaignObject.attributes.customerCredit}
                      </li>
                    )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.securityDeposit && (
                      <li>
                        Security Deposit:{' '}
                        {campaignObject.attributes.securityDeposit}
                      </li>
                    )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.apr && (
                      <li>APR %: {campaignObject.attributes.apr}</li>
                    )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.aprMonths && (
                      <li>APR Month: {campaignObject.attributes.aprMonths}</li>
                    )}
                  {campaignObject.attributes &&
                    campaignObject.attributes.aprCustomerCredit && (
                      <li>
                        APR Customer Credit:{' '}
                        {campaignObject.attributes.aprCustomerCredit}
                      </li>
                    )}
                </ul> */}
              </div>
            );
          }
          
        })}
        <p>
          {this.props.theme === 'jaguar' ? 'Jaguar' : 'Land Rover'} Co-op Funds:{' '}
          {coopFunds ? 'Yes' : 'No'}
        </p>
        {/* <Separator /> */}
        {this.renderSummaryBlocks()}
        <OrderTotal total={orderTotal} />
        <StyledContact>
          <StyledCol>
            <h3>
              Contact Information
              <EditButton onClick={() => this.props.editStep(3)}>
                Edit
              </EditButton>
            </h3>
            <p>
              first name: <span>{selectedDealer.firstName}</span>
            </p>
            <p>
              last name: <span>{selectedDealer.lastName}</span>
            </p>
            <p>
              phone number: <span>{selectedDealer.phone}</span>
            </p>
            <p>
              toll free number: <span>{selectedDealer.tollFree}</span>
            </p>
            <p>
              email: <span>{selectedDealer.email}</span>
            </p>
            <p>
              website: <span>{selectedDealer.website}</span>
            </p>
            {selectedDealer && <p>Retailer: {selectedDealer.name ? selectedDealer.name.replace('&#8217;', "'") : ''}</p>}
            <p>
              address: <span>{selectedDealer.address}</span>
            </p>
            <p>
              city: <span>{selectedDealer.city}</span>
            </p>
            <p>
              state: <span>{selectedDealer.state}</span>
            </p>
            <p>
              Zip: <span>{selectedDealer.zip}</span>
            </p>
          </StyledCol>
          <StyledCol className="second-col">
            <h3>
              Retailer Operating Hours
              <EditButton onClick={() => this.props.editStep(3)}>
                Edit
              </EditButton>
            </h3>

            {selectedDealer.hours &&
              Object.keys(selectedDealer.hours).map(day => {
                return selectedDealer.hours[day].closed ? (
                  <p key={day} className="OperationHour">
                    {day}: <span>Closed</span>
                  </p>
                ) : (
                  <p key={day} className="OperationHour">
                    {day}:{' '}
                    <span>
                      {selectedDealer.hours[day].open} -{' '}
                      {selectedDealer.hours[day].close}
                    </span>
                  </p>
                );
              })}
          </StyledCol>
        </StyledContact>
      </div>
    ) : (
      <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
        loading...
      </div>
    );
  }
}

export default SummaryAndSubmit;
