import React from 'react';
import styled from 'styled-components';
import {
  colors as jagColors,
  breakpoints
} from '../style-utilities/jagVariables';
// logos
import jagLogo from '../images/jaguar/jag-logo-png@2x.png';
import lrLogo from '../images/landrover/lr-logo.png';

const StyledLinkedLogo = styled.a`
  &.jaguar {
    img {
      max-width: 92px;
      margin-bottom: 0;
    }

    @media (min-width: ${breakpoints.desktop}) {
      img {
        max-width: 115px;
      }
    }
  }

  &.landrover {
    img {
      max-width: 115px;
      margin-bottom: 0;
    }

    /* @media (min-width: ${breakpoints.desktop}) {
      img {
        max-width: 115px;
      }
    } */
  }
`;

const LinkedLogo = ({ theme }) => {
  return (
    <StyledLinkedLogo
      className={theme}
      href={
        theme === 'jaguar'
          ? 'https://www.jaguarusa.com/index.html'
          : 'https://www.landroverusa.com/'
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={theme === 'jaguar' ? jagLogo : lrLogo} alt={theme} />
    </StyledLinkedLogo>
  );
};

export default LinkedLogo;
