export const colors = {
  primary: '#0c121c',
  gray1: '#3C3C3B',
  gray2: '#4F4F4E',
  gray3: '#636362',
  gray4: '#767675',
  gray5: '#f5f5f5',
  textGray: 'rgba(255,255,255,.5)',
  inputGray: 'rgba(255,255,255,.7)'
};

export const breakpoints = {
  mobileMax: '767px',
  tablet: '768px',
  desktop: '1200px',
  ultraWideDesktop: '1680px'
};
