import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../style-utilities/jagVariables';

const StyledCampaignDetails = styled.div`
  padding-bottom: 45px;
  padding-top: 45px;

  h2 {
    font-size: 24px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-bottom: 75px;
    padding-top: 75px;

    h2 {
      font-size: 28px;
    }

    .campaigns {
      > div {
        margin-bottom: 40px;
      }
    }
  }
`;

const CampaignDetails = ({ headline, children }) => {
  return (
    <StyledCampaignDetails className="page-container">
      <h2>{headline}</h2>
      <div className="campaigns">{children}</div>
    </StyledCampaignDetails>
  );
};

export default CampaignDetails;
