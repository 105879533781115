import React, { Component } from 'react';
import styled from 'styled-components';

import { colors, breakpoints } from '../style-utilities/jagVariables';
import EditButton from './EditButton';

const Wrapper = styled.div`
  padding: 0px;
  .direct-mail,
  .email {
    margin: 20px 0px;
    background-color: ${colors.gray1};
    color: #fff;
    border-radius: 3px;
  }
  .count,
  .description {
    padding: 0px;
    display: inline-block;
  }
  .description {
    border-right: 1px solid ${colors.gray4};
    h2 {
      text-transform: uppercase;
      margin: auto;
      font-size: 25px;
      text-align: center;
      position: relative;
      top: 40%;
    }
  }
  .count {
    .customer {
      margin: 0px;
      border-bottom: 1px solid ${colors.gray4};
      text-transform: uppercase;
      .first-col,
      .second-col,
      .third-col {
        padding: 20px;
      }
      .second-col{
        padding-left: 0px;
      }
      .third-col {
        padding: 20px 10px 0 0;
        text-align: right;
      }
      .first-col p {
        margin-bottom: 0px;
      }
    }
    .conquest {
      margin: 0px;
      text-transform: uppercase;
      .first-col,
      .second-col,
      .third-col {
        padding: 20px;
      }
      .second-col{
        padding-left: 0px;
      }
      .third-col {
        padding: 20px 10px 0 0;
        text-align: right;
      }
      .first-col p {
        margin-bottom: 0px;
      }
    }
  }
  .total {
    align-items: center;
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0;

    .note,
    .total-count {
      padding: 0px;
    }
    .total-count {
      background-color: ${colors.gray1};
      border-radius: 3px;
      p,
      span {
        display: inline-block;
        font-size: 25px;
        padding: 20px;
        margin: 0px;
        text-transform: uppercase;
      }
      .left {
        text-align: left;
      }
      .right {
        text-align: right;
      }
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    .description {
      border-right: none;
      border-bottom: 1px solid ${colors.gray4};
      h2 {
        font-size: 18px;
        position: relative;
        top: 0;
        text-align: left;
        padding: 15px 20px;
      }
    }
    .email,
    .direct-mail {
      display: flex;
      .first-col {
        p {
          font-size: 12px;
        }
        width: 50%;
      }
      .second-col,
      .third-col {
        width: 25%;
        font-size: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column-reverse;
      .note {
        margin-top: 10px;
      }
      .left,
      .right {
        p,
        span {
          padding: 10px 0px;
          font-size: 20px;
        }
      }
      .left {
        width: 50%;
      }
      .right {
        text-align: right !important;
        width: 50%;
      }
    }
  }
`;

class SummaryBlock extends Component {
  render() {
    const {
      theme,
      campaign,
      subTotals,
      orderCounts = {
        dmCustomerCounts: 0,
        dmConquestCounts: 0,
        emailCustomerCounts: 0,
        emailConquestCounts: 0,
        postCustomerCounts: 0,
        postConquestCounts: 0
      }
    } = this.props;

    const useTriFold = theme === 'jaguar' && campaign.type === 'Full Line';

    return (
      <div>
        <h3>
          {campaign.title2} Materials{' '}
          <EditButton onClick={() => this.props.editStep(2)}>Edit</EditButton>
        </h3>
        {campaign.type === 'Full Line' && (
          <div
            className="full-line-counts-disclaimer"
            style={{
              background: '#f5f5f5',
              padding: '10px',
              color: 'black',
              display: 'inline-block',
              fontWeight: 'bold'
            }}
          >
            This conquest quantity is for your retailers’ full line (all
            vehicles). We will reach out to you with a more defined count.
          </div>
        )}
        <Wrapper className="container-fluid">
          {/* First Block */}
          <div className="row direct-mail">
            <div className="col-lg-5 description">
              {!useTriFold && <h2>bi-fold direct mail</h2>}
              {useTriFold && <h2>tri-fold direct mail</h2>}
            </div>
            <div className="col-lg-7 count">
              <div className="row customer">
                <div className="col-sm-6 first-col">
                  <p>customer count</p>
                </div>
                <div className="col-sm-3 second-col">{orderCounts.dmCustomerCounts || 0}</div>
                <div className="col-sm-3 third-col">${(orderCounts.dmCustomerCounts * campaign.costs.dm).toFixed(
                      2
                    )}</div>
              </div>
              <div className="row conquest">
                <div className="col-sm-6 first-col">
                  <p>conquest count</p>
                </div>
                <div className="col-sm-3 second-col">
                  {orderCounts.dmConquestCounts || 0}
                </div>
                <div className="col-sm-3 third-col">
                  $
                  {!useTriFold &&
                    (orderCounts.dmConquestCounts * campaign.costs.dm).toFixed(
                      2
                    )}
                  {useTriFold &&
                    (
                      orderCounts.dmConquestCounts * campaign.costs.triDm
                    ).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Post Block */}
          <div className="row email">
            <div className="col-lg-5 description">
              <h2>Postcard</h2>
            </div>
            <div className="col-lg-7 count">
              <div className="row customer">
                <div className="col-lg-6 first-col">
                  <p>customer count</p>
                </div>
                <div className="col-lg-3 second-col">{orderCounts.postCustomerCounts || 0}</div>
                <div className="col-lg-3 third-col">${(
                    orderCounts.postCustomerCounts * campaign.costs.post
                  ).toFixed(2) || '0.00'}</div>
              </div>
              <div className="row conquest">
                <div className="col-lg-6 first-col">
                  <p>conquest count</p>
                </div>
                <div className="col-lg-3 second-col">
                  {orderCounts.postConquestCounts || 0}
                </div>
                <div className="col-lg-3 third-col">
                  $
                  {(
                    orderCounts.postConquestCounts * campaign.costs.post
                  ).toFixed(2) || '0.00'}
                </div>
              </div>
            </div>
          </div>
          {/* Second Block */}
          <div className="row email">
            <div className="col-lg-5 description">
              <h2>email</h2>
            </div>
            <div className="col-lg-7 count">
              <div className="row customer">
                <div className="col-lg-6 first-col">
                  <p>customer count</p>
                </div>
                <div className="col-lg-3 second-col">{orderCounts.emailCustomerCounts || 0}</div>
                <div className="col-lg-3 third-col">${(
                    orderCounts.emailCustomerCounts * campaign.costs.email
                  ).toFixed(2) || '0.00'}</div>
              </div>
              <div className="row conquest">
                <div className="col-lg-6 first-col">
                  <p>conquest count</p>
                </div>
                <div className="col-lg-3 second-col">
                  {orderCounts.emailConquestCounts || 0}
                </div>
                <div className="col-lg-3 third-col">
                  $
                  {(
                    orderCounts.emailConquestCounts * campaign.costs.email
                  ).toFixed(2) || '0.00'}
                </div>
              </div>
            </div>
          </div>
          {/* Third Block */}
          <div className="row total">
            <div className="col-lg-5 note">
              <p>
                Note: Retailers are able to add their Retailer information to
                each template and orders are eligible for the JLRMP Co-op
                Program.
              </p>
            </div>
            <div className="col-lg-7 total-count">
              <div className="row">
                <div className="col-lg-6 left">
                  <p>total:</p>
                </div>
                <div className="col-lg-6 right">
                  <span>
                    $
                    {typeof subTotals[campaign.id] === 'number'
                      ? subTotals[campaign.id].toFixed(2)
                      : '0.00'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }
}

export default SummaryBlock;
