import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
// import styles
import StyledCountsBlock from './CountsBlock.styles';

class CountsBlock extends Component {
  initialState = {
    modalIsOpen: false,
    images: {},
    form: {
      dmCustomerCounts: 0,
      dmConquestCounts: 0,
      emailCustomerCounts: 0,
      emailConquestCounts: 0,
      postCustomerCounts: 0,
      postConquestCounts: 0,
      subTotals: {
        dmCustomerCounts: 0,
        dmConquestCounts: 0,
        emailCustomerCounts: 0,
        emailConquestCounts: 0,
        postCustomerCounts: 0,
        postConquestCounts: 0,
      }
    },
    fieldStatus: {
      requiredFlag: true,
      checkform: false,
      dmCustomerCounts: false,
      dmConquestCounts: false,
      emailCustomerCounts: false,
      emailConquestCounts: false
    }
  };

  state = this.initialState;

  // check props for climate and set images state accordingly
  static getDerivedStateFromProps(props, state) {
    const images = props.images;

    if (props.hasClimateSelect && props.climate) {
      return {
        ...state,
        images: {
          dmThumb: images[props.climate].dmThumb,
          dmFull: images[props.climate].dmFull,
          emThumb: images[props.climate].emThumb,
          emFull: images[props.climate].emFull
        },
        counts: props.counts[props.type]
      };
    } else {
      return {
        ...state,
        images: {
          dmThumb: images.dmThumb,
          dmFull: images.dmFull,
          emThumb: images.emThumb,
          emFull: images.emFull,
          postThumb: images.postThumb,
          postFull: images.postFull
        },
        counts: props.counts[props.type]
      };
    }
  }

  handleInputChange = event => {
    const useTriFold =
      this.props.theme === 'jaguar' && this.props.campaignType === 'Full Line';
    const input = event.target;
    const { costs } = this.props;
    // if the input is a 'dm' input, set type to dm
    // if not, set it to email
    let type;
    // if (useTriFold) {
    //   type = input.name.indexOf('dm') !== -1 ? 'triDm' : 'email';
    // } else {
    //   type = input.name.indexOf('dm') !== -1 ? 'dm' : 'email';
    // }
    if (input.name === 'dmCustomerCounts' || input.name === 'dmConquestCounts') {
      type = 'dm';
    } else if (input.name === 'postCustomerCounts' || input.name === 'postConquestCounts') {
      type = 'post';
    } else if (input.name === 'emailCustomerCounts' || input.name === 'emailConquestCounts') {
      type = 'email';
    } else {
      console.log('Something went wrong with the input name type. Or a new type has been added and not accounted for in CountsBlock');
    }
    this.props.updateOrderCounts(event);
    this.setState(
      {
        form: {
          [input.name]: input.value,
          subTotals: {
            ...this.state.form.subTotals,
            [input.name]: input.value * costs[type]
          }
        }
      },
      () => {
        this.props.updateOrderTotal(this.state.form.subTotals);
        this.checkFormValidity();
      }
    );

  };

  checkFormValidity = () => {
    let requiredFlag = true;
    let checkForm = false;
    let dmCustomer = false;
    let dmConquest = false;
    let emailCustomer = false;
    let emailConquest = false;
    let allZero = false;

    if (this.state.form.subTotals.dmCustomerCounts > 0 && this.state.form.subTotals.emailCustomerCounts == 0) {
      emailCustomer = true;
      requiredFlag = true;
      checkForm = true;
      this.props.handleSecondStep(false);
    } else if (this.state.form.subTotals.emailCustomerCounts > 0 && this.state.form.subTotals.dmCustomerCounts == 0) {
      dmCustomer = true
      requiredFlag = true;
      checkForm = true;
      this.props.handleSecondStep(false);
    } else if (this.state.form.subTotals.dmConquestCounts > 0 && this.state.form.subTotals.emailConquestCounts == 0) {
      emailConquest = true;
      requiredFlag = true;
      checkForm = true;
      this.props.handleSecondStep(false);
    } else if (this.state.form.subTotals.emailConquestCounts > 0 && this.state.form.subTotals.dmConquestCounts == 0) {
      dmConquest = true;
      requiredFlag = true;
      checkForm = true;
      this.props.handleSecondStep(false);
    } else if (this.state.form.subTotals.dmCustomerCounts == 0 && this.state.form.subTotals.emailCustomerCounts == 0 && this.state.form.subTotals.dmConquestCounts == 0 && this.state.form.subTotals.emailConquestCounts == 0) {
      requiredFlag = true;
      checkForm = true;
      allZero = true;
      this.props.handleSecondStep(false);
    } else {
      requiredFlag = false;
      this.props.handleSecondStep(true);
    }

    this.setState({
      fieldStatus: {
        requiredFlag: requiredFlag,
        checkform: checkForm,
        dmCustomerCounts: dmCustomer,
        dmConquestCounts: dmConquest,
        emailCustomerCounts: emailCustomer,
        emailConquestCounts: emailConquest
      }
    });

  };

  toggleModal = img => {
    this.setState(
      {
        modalImage: img
      },
      this.setState({
        modalIsOpen: !this.state.modalIsOpen
      })
    );
  };

  renderCounts = (count) => {
    if (count === null) {
      return 0;
    } else {
      return count;
    }
  }; 
  componentWillUnmount() {
    // clears counts and costs when this component/campaign has been deselected
    const zeroForm = {
      dmCustomerCounts: 0,
      dmConquestCounts: 0,
      emailCustomerCounts: 0,
      emailConquestCounts: 0,
      postCustomerCounts: 0,
      postConquestCounts: 0
    }
    this.props.updateOrderTotal(zeroForm);
  }

  componentDidUpdate(prevProps, prevState) {
    const { counts } = this.props;
    if (counts < 1) {
      this.checkFormValidity();
    }
  }

  render() {
    const {
      theme,
      campaignType,
      images = { cold: {}, warm: {} },
      type,
      costs,
      orderCounts = {}
    } = this.props;
    const showTriFold = theme === 'jaguar' && campaignType === 'Full Line';
    const { counts } = this.state;
    const valuesArr = Object.values(this.state.form.subTotals);
    const blockTotal = valuesArr.reduce((total, add) => {
      return parseFloat(total) + parseFloat(add);
    }, 0);


    return (
      <StyledCountsBlock>
        <div className="order-form">
          <section className="description">
            <div className="thumbnail">
              <button
                type="button"
                style={{ backgroundColor: 'transparent', border: 'none' }}
                onClick={() => this.toggleModal(this.state.images.dmFull)}
              >
                <img src={this.state.images.dmThumb || ''} alt="" />
              </button>
            </div>
            <div className="copy">
              {!showTriFold && (
                <>
                  <h4>Bi-Fold Direct Mail</h4>
                  <ul>
                    <li>9 x 6 self-mailer</li>
                    <li>120# cover</li>
                    <li>5/5</li>
                    <li>Aqueous coating</li>
                  </ul>
                </>
              )}
              {showTriFold && (
                <>
                  <h4>Tri-Fold Direct Mail</h4>
                  <ul>
                    <li>8.5 x 6</li>
                    <li>120# cover</li>
                    <li>5/5</li>
                    <li>Coating</li>
                  </ul>
                </>
              )}
            </div>
          </section>
          <section className="counts">

            <div className="row customer-count">

              {this.state.fieldStatus.dmCustomerCounts ? (<div className="field-msg">Enter at least 1 in this field.</div>) : ('')}
              
              <p className="counts-label">Enter Customer Counts ({this.renderCounts(counts.dmCustomer)}  max):</p>

                {counts.dmCustomer === 0 ? (
                  <input
                  name="dmCustomerCounts"
                  type="number"
                  max={this.renderCounts(counts.dmCustomer)}
                  min="0"
                  placeholder={0}
                  onChange={this.handleInputChange}
                  disabled
                  />
                ) : (
                  <input
                  name="dmCustomerCounts"
                  type="number"
                  max={this.renderCounts(counts.dmCustomer)}
                  min="0"
                  placeholder={0}
                  onChange={this.handleInputChange}
                  />
                )}
              
              <span className="amount">
                ${this.state.form.subTotals.dmCustomerCounts.toFixed(2)}
              </span>
            </div>

            <div className="row conquest-count">

            {this.state.fieldStatus.dmConquestCounts ? (<div className="field-msg">Enter at least 1 in this field.</div>) : ('')}

              <p className="counts-label">
                Enter Conquest Counts ({this.renderCounts(counts.dmConquest)} max):
              </p>

              <input
                name="dmConquestCounts"
                type="number"
                max={this.renderCounts(counts.dmConquest)}
                min="0"
                placeholder={0}
                onChange={this.handleInputChange}
              />

              <span className="amount">
                ${this.state.form.subTotals.dmConquestCounts.toFixed(2)}
              </span>
            </div>
            {/* This field prevents the form from submitting if fieldStatus does not pass */}
            {/* <div className="requiredField">
              <input type="number" required={this.state.fieldStatus.requiredFlag}></input>
            </div> */}

          </section>
        </div>

        {/* bctest */}
        {this.props.type === 'Tax Advantage' ? 
        <div className="order-form">
          <section className="description">
            <div className="thumbnail">
              <button
                type="button"
                style={{ backgroundColor: 'transparent', border: 'none' }}
                onClick={() => this.toggleModal(this.state.images.postFull)}
              >
                <img src={this.state.images.postThumb || ''} alt="" />
              </button>
            </div>
            <div className="copy">
              <h4>Postcard</h4>
              <ul>
                <li>9 X 6 postcard</li>
                <li>120# Cover</li>
                <li>5/5</li>
                <li>Aqueous coating</li>
              </ul>
            </div>
          </section>
          <section className="counts">
            
            <div className="row customer-count">
             
              <p className="counts-label">Enter Customer Counts ({this.renderCounts(counts.postCustomer)} max):</p>

              {counts.postCustomer === 0 ? (
                <input
                  name="postCustomerCounts"
                  type="number"
                  max={this.renderCounts(counts.postCustomer)}
                  min="0"
                  placeholder={0}
                  onChange={this.handleInputChange}
                  disabled
                />
              ) : (
                  <input
                    name="postCustomerCounts"
                    type="number"
                    max={this.renderCounts(counts.postCustomer)}
                    min="0"
                    placeholder={0}
                    onChange={this.handleInputChange}
                  />
                )}

              <span className="amount">
                ${this.state.form.subTotals.postCustomerCounts.toFixed(2)}
              </span>
            </div>
            <div className="row conquest-count">
              {this.state.fieldStatus.postConquestCounts ? (<div className="field-msg">Enter at least 1 in this field.</div>) : ('')}
              <p className="counts-label">
                Enter Conquest Counts ({this.renderCounts(counts.postConquest)} max):
              </p>
              <input
                name="postConquestCounts"
                type="number"
                max={this.renderCounts(counts.postConquest)}
                min="0"
                placeholder={0}
                onChange={this.handleInputChange}
              />
              <span className="amount">
                ${this.state.form.subTotals.postConquestCounts.toFixed(2)}
              </span>
            </div>
          </section>
        </div>
        : ''}

        
        
        <div className="order-form">
          <section className="description">
            <div className="thumbnail">
              <button
                type="button"
                style={{ backgroundColor: 'transparent', border: 'none' }}
                onClick={() => this.toggleModal(this.state.images.emFull)}
              >
                <img src={this.state.images.emThumb || ''} alt="" />
              </button>
            </div>
            <div className="copy">
              <h4>Email</h4>
              <ul>
                <li>Includes 2 email deployments</li>
              </ul>
            </div>
          </section>
          <section className="counts">
            <div className="row customer-count">
              {this.state.fieldStatus.emailCustomerCounts ? (<div className="field-msg">Enter at least 1 in this field.</div>) : ('')}
              <p className="counts-label">Enter Customer Counts ({this.renderCounts(counts.emCustomer)} max):</p>

              {counts.emCustomer === 0 ? (
                <input
                  name="emailCustomerCounts"
                  type="number"
                  max={this.renderCounts(counts.emCustomer)}
                  min="0"
                  placeholder={0}
                  onChange={this.handleInputChange}
                  disabled
                />
              ) : (
                <input
                  name="emailCustomerCounts"
                  type="number"
                  max={this.renderCounts(counts.emCustomer)}
                  min="0"
                  placeholder={0}
                  onChange={this.handleInputChange}
                />
              )}

              <span className="amount">
                ${this.state.form.subTotals.emailCustomerCounts.toFixed(2)}
              </span>
            </div>
            <div className="row conquest-count">
            {this.state.fieldStatus.emailConquestCounts ? (<div className="field-msg">Enter at least 1 in this field.</div>) : ('')}
              <p className="counts-label">
                Enter Conquest Counts ({this.renderCounts(counts.emConquest)} max):
              </p>
              <input
                name="emailConquestCounts"
                type="number"
                max={this.renderCounts(counts.emConquest)}
                min="0"
                placeholder={0}
                onChange={this.handleInputChange}
              />
              <span className="amount">
                ${this.state.form.subTotals.emailConquestCounts.toFixed(2)}
              </span>
            </div>
          </section>
        </div>
        <div className="total-row">
          <div className="note">
            Note: Retailers are able to add their Retailer information to each
            template and orders are eligible for the JLRMP Co-op Program.
          </div>
          <div className="order-total">
            <span className="total-label">TOTAL:</span>
            <span className="total-amount">${blockTotal.toFixed(2)}</span>
          </div>
        </div>
        <Modal
          style={{
            textAlign: 'center',
            maxWidth: '920px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          isOpen={this.state.modalIsOpen}
          toggle={this.toggleModal}
        >
          <ModalBody style={{padding: '0'}}>
            <button
              onClick={this.toggleModal}
              style={{
                position: 'absolute',
                right: '25px',
                fontSize: '30px',
                border: 'none',
                fontWeight: 'bold'
              }}
            >
              X
            </button>
            <img src={this.state.modalImage} style={{marginTop: '30px'}} alt=""  />
          </ModalBody>
        </Modal>
      </StyledCountsBlock>
    );
  }
}

export default CountsBlock;
