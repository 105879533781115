import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { breakpoints } from '../style-utilities/jagVariables';

const StyledImageSlider = styled(Slider)`
  /* max-height: 212px; */
  width: 100%;
  overflow: hidden;

  .slick-track,
  .slick-list,
  .slick-slide,
  .slick-slide img {
    /* height: 212px; */
    margin-bottom: 0px;
  }

  .slick-slide img {
    object-fit: cover;
  }
  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    /* fit img content into view will need to be removed or adjusted depending on hero slide images */
    .slick-slide img {
      position: relative;
      /* top: -70px; */
      /* height: 400px; */
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    /* max-height: 400px; */
    /* height: 400px; */
    width: 60%;

    .slick-track,
    .slick-list,
    .slick-slide,
    .slick-slide img {
      /* position: relative;
      top: 0px;
      height: 400px; */
    }
  }

  @media (min-width: ${breakpoints.ultraWideDesktop}) {
    .slick-list {
      top: -50px;
    }
  }
`;

const ImageSlider = ({ images, altText }) => {
  let scrollToggle = false;
  if(images.length > 1) {
    scrollToggle = true
  }
  const settings = {
    autoplay: scrollToggle,
    autoplaySpeed: 8000,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <StyledImageSlider {...settings}>
      {images.map((image, index) => (
        <img key={image} src={image} alt={altText[index]} />
      ))}
    </StyledImageSlider>
  );
};

export default ImageSlider;
