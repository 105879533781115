import React, { Component } from "react";
import _ from "lodash";
import jagVehicleTypes from "../../static-data/jagVehicleTypes";
import lrVehicleTypes from "../../static-data/lrVehicleTypes";
import jagNationalOffers from "../../static-data/jagNationalOffers";
// import styles
import StyledCampaignBuilder from "./CampaignBuilder.styles";
import lrNationalOffers from "../../static-data/lrNationalOffers";

//import components
import CampaignIpace from './CampaignIpace';

class CampaignBuilder extends Component {
  state = {
    canUpdateNatlOffer: false,
    expanded: false,
    useNationalOffer: false,
    vehicleTypes: this.props.vehicles.filter(
      vehicle =>
        vehicle.campaign === this.props.campaign.campaignMatch &&
        vehicle.brand.toLowerCase().replace(' ', '') === this.props.theme
    ),
    nationalOffers:
      this.props.theme === "jaguar" ? jagNationalOffers : lrNationalOffers
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      activeCampaigns,
      campaign,
      copyFlag,
      expandCopy,
      setHiddenFields
    } = this.props;
    // if the user chooses to use the national campaign
    // and the national offer can be updated, call setNationalOfferAttr
    // with the currently selected vehicle
    if (
      activeCampaigns[campaign.id] &&
      activeCampaigns[campaign.id].attributes &&
      this.state.useNationalOffer &&
      this.state.canUpdateNatlOffer
    ) {
      const { vehicle } = activeCampaigns[campaign.id];
 
      this.setNationalOfferAttr(vehicle);
      // but after that, disable the ability to update,
      // so we don't get an infinite loop
      this.setState({
        canUpdateNatlOffer: false
      });
    }
    // if the user switches useNationalOffer to no, clear the fields
    if (prevState.useNationalOffer && !this.state.useNationalOffer) {
      this.setNationalOfferAttr();
    }

    this.secondVehicleOption();
  }

  secondVehicleOption = event => {
    const { copyFlag, expandCopy, campaign, activeCampaigns } = this.props;
    // checked to see if this component is a copy of another campaign (second vehicle option)
    if (copyFlag === true) {
      if (expandCopy === true && this.state.expanded !== true) {
        this.handleCampaignSelect();
      } else if (expandCopy === false && this.state.expanded !== false) {
        this.handleCampaignSelect();
      }
    }
  };

  handleCampaignSelect = event => {
    const { copyFlag, expandCopy, campaign, activeCampaigns } = this.props;
    this.setState({
      expanded: !this.state.expanded
    });

    // determines if the original campaign is expanded (related to second vehicle option)
    if (this.props.copyFlag !== true && campaign.id === "2019-pre-owned-1") {
      this.props.handleLinkedCampaign();
    }

    // update campaigns on state
    this.props.selectCampaign({
      ...this.props.campaign,
      counts: {
        emailConquestCounts: 0,
        dmConquestCounts: 0,
        postConquestCounts: 0,
        emailCustomerCounts: 0,
        dmCustomerCounts: 0,
        postCustomerCounts: 0,
      }
    });
    //2019 Jaguar useNational Offer is to be applied on all vehicles. Remove this block to revert to regular functionality
    // this.setState({
      // useNationalOffer: true,
      // canUpdateNatlOffer: true
    // });
  };

  handleInputChange = event => {
    const input = event.target;

    if (input.name === "nationalOffer") {
      // set local state to use national c
      // and enable a one-time update of the national offer
      this.setState({
        useNationalOffer: input.value === "yes",
        canUpdateNatlOffer: true
      });
  
    } else if (input.name === "vehicle") {
      const vehiclesObject = _.keyBy(this.state.vehicleTypes, "displayName");
      console.log('vehiclesObject', vehiclesObject);
      // enable a one-time update of the national offer
      this.setState({
        canUpdateNatlOffer: true
      });
      console.log('input', input);
      this.props
        .setHiddenFields(vehiclesObject[input.value], this.props.campaign.id)
        .then(() => {
          this.props.updateCampaignAttr(this.props.campaign, input);
        });
    }
    // if optional vehicle is not selected remove this campaign
    if (input.name !== "vehicle") {
      this.props.updateCampaignAttr(this.props.campaign, input);
    }
  };
  
  setNationalOfferAttr = vehicle => {
    // console.log('vehicle', vehicle.attributes.vehicle);
    // console.log('nationalOffers', this.state.nationalOffers[vehicle.attributes.vehicle]);
    // const data = this.state.nationalOffers[vehicle.attributes.vehicle];
    const data = this.state.nationalOffers[vehicle];
    this.props.setNationalOfferAttr(data, this.props.campaign.id);
  };

  render() {
    // destructure this.props
    const { campaign, activeCampaigns } = this.props;
    // get campaignattres with fallback
    const campaignAttrs =
      activeCampaigns[campaign.id] && activeCampaigns[campaign.id].attributes
        ? activeCampaigns[campaign.id].attributes
        : {};

    const campaignAttrs2 =
      activeCampaigns[campaign.id] && activeCampaigns[campaign.id].attributes
        ? activeCampaigns[campaign.id].attributes
        : {};
    // determine if this campaign is active
    let isActive = activeCampaigns.hasOwnProperty(campaign.id);

    // make the form optional if only 1 form is filled
    if (this.props.copyFlag === true) {
      isActive = false;
    }
    // does the selected vehicle have a national offer?
    const vehicleHasNationalOffer = this.state.nationalOffers[
      campaignAttrs.vehicle
    ];

    return (
      <StyledCampaignBuilder>
        {this.props.copyFlag ? (
          ""
        ) : (
          <div
            className={`menu-header ${this.props.theme} ${
              this.props.copyFlag ? "hideOption" : ""
            }`}
          >
            <label htmlFor={`selectCampaign-${campaign.id}`}>
              {campaign.title2}
              <input
                id={`selectCampaign-${campaign.id}`}
                type="checkbox"
                onChange={this.handleCampaignSelect}
              />
            </label>
          </div>
        )}

        {campaign.id === "2019-f-type-1" ||
        campaign.id === "2019-f-type-2" ||
        campaign.id === `2019-pre-owned-1` ||
        campaign.id === `2019-pre-owned-2` ? (
          <div className={`menu-body ${this.state.expanded ? "expanded" : ""}`}>
            <div className="attributes">
              <div className="vehicle-type">
                <div className="vehicle-type">
                  <small className="small-show">
                    {this.props.copyFlag
                      ? ""
                      : "Please select up to 2 vehicles you would like included in this offer. "}
                  </small>
                </div>
                <label htmlFor="vehicleType">
                  {this.props.copyFlag ? "2. " : "1. "}
                  Vehicle and Model Year{" "}
                  {this.props.copyFlag ? "(Optional) " : ""}
                </label>
                <select
                  ref="vehicle"
                  required={this.props.copyFlag ? false : isActive}
                  id="vehicle"
                  name="vehicle"
                  onChange={this.handleInputChange}
                >
                  <option defaultValue value="">
                    Select Vehicle
                  </option>
                  {/* find the corresponding array of vehicle types based on campaign
                    render an option for each one
                  */}
                  {this.state.vehicleTypes.map(vehicle => {
                    return (
                      <option key={vehicle.slug} value={vehicle.displayName}>
                        {vehicle.displayName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <hr className="field-separator" />
              {campaign.type === 'Holiday Sales Event' &&
                isActive && (
                  <>
                    <select
                      className="hidden"
                      required
                      type="checkbox"
                      style={{
                        border: 'none',
                        position: 'absolute',
                        pointerEvents: 'none',
                        height: 0,
                        width: 0,
                        top: '64px'
                      }}
                    >
                      <option value="" defaultValue>
                        null
                      </option>
                      <option
                        value="proceed"
                        selected={
                          !(
                            this.state.useNationalOffer &&
                            !vehicleHasNationalOffer
                          )
                        }
                      >
                        proceed
                      </option>
                    </select>
                    <div className="national-offer">
                      <label htmlFor="nationalOffer">
                        National Offer{" "}
                        {!vehicleHasNationalOffer &&
                          this.state.useNationalOffer && (
                            <small style={{ color: 'red' }}>
                              There is no National offer for this vehicle.
                              Please select No in the dropdown and enter your
                              own offer amounts in the fields, or select a
                              different vehicle.
                            </small>
                          )}
                      </label>
                      <select
                        required={isActive}
                        id="nationalOffer"
                        ref="nationalOffer"
                        name="nationalOffer"
                        onChange={event => {
                          this.handleInputChange(event);
                        }}
                      >
                        <option defaultValue value="">
                          Select an option
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      {}
                    </div>
                  </>
                )}
              <>
                {/* <div className="input-group">
                  <label htmlFor="">
                    Lease Offer <small>(ex: $300)</small>
                  </label>
                  <input
                    disabled={this.state.useNationalOffer}
                    required={isActive}
                    type="text"
                    name="leaseOffer"
                    value={campaignAttrs['leaseOffer'] || ''}
                    onChange={this.handleInputChange}
                  />
                </div> */}

                {/* <div className="input-group">
                  <label htmlFor="">
                    Cash Due at Signing <small>(ex: $3000)</small>
                  </label>
                  <input
                    disabled={this.state.useNationalOffer}
                    required={isActive}
                    type="text"
                    name="dueAtSigning"
                    value={campaignAttrs['dueAtSigning'] || ''}
                    onChange={this.handleInputChange}
                  />
                </div> */}
                {/* <div className="input-group">
                  <label htmlFor="">
                    Security Deposit <small>(ex: $0)</small>
                  </label>
                  <input
                    disabled={this.state.useNationalOffer}
                    required={isActive}
                    type="text"
                    name="securityDeposit"
                    value={campaignAttrs['securityDeposit'] || ''}
                    onChange={this.handleInputChange}
                  />
                </div> */}
                <div className="input-group">
                  <label htmlFor="">
                    APR % <small>(ex: 1.9%)</small>
                  </label>
                  <input
                    disabled={this.state.useNationalOffer}
                    required={isActive}
                    type="text"
                    name="apr"
                    value={campaignAttrs["apr"] || ""}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="">
                    Month Term <small>(ex: 39 months)</small>
                  </label>
                  <input
                    disabled={this.state.useNationalOffer}
                    required={isActive}
                    type="text"
                    name="monthTerm"
                    value={campaignAttrs["monthTerm"] || ""}
                    onChange={this.handleInputChange}
                  />
                </div>
                {/* <div className="input-group">
                  <label htmlFor="">
                    APR Month <small>(ex: 36)</small>
                  </label>
                  <input
                    disabled={this.state.useNationalOffer}
                    required={isActive}
                    type="text"
                    name="aprMonths"
                    value={campaignAttrs['aprMonths'] || ''}
                    onChange={this.handleInputChange}
                  />
                </div> */}
              </>
            </div>
          </div>
        ) : (
          ''
        )}

        {campaign.id === `I-PACE` ? (
          <CampaignIpace
            campaign={campaign}
            expanded={this.state.expanded}
            isActive={isActive}
            activeCampaigns={activeCampaigns}
            vehicleTypes={this.state.vehicleTypes}
            useNationalOffer={this.state.useNationalOffer}
            handleInputChange={this.handleInputChange}
          />
        ) : (
          ''
        )}
      </StyledCampaignBuilder>
    );
  }
}

export default CampaignBuilder;
