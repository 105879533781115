import React from 'react';
import styled from 'styled-components';
import {
  colors as jagColors,
  breakpoints
} from '../../style-utilities/jagVariables';

const StyledFooterNav = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;

    li {
      list-style: none;
    }
  }

  a {
    color: ${jagColors.gray1};
    font-size: 0.85em;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
  }

  .copyright {
    font-size: 0.85em;
    letter-spacing: 1px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    margin-top: 0;

    a {
      font-size: initial;
    }
  }
`;

const FooterNav = ({ theme }) => {
  const year = new Date().getFullYear();

  return theme === 'jaguar' ? (
    <StyledFooterNav>
      <ul>
        <li>
          <a
            href="https://www.jaguarusa.com/contact-us/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact
          </a>
        </li>
        <li>
          <a
            href="https://www.jaguarusa.com/terms-conditions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; Conditions
          </a>
        </li>
        <li>
          <a
            href="https://www.jaguarusa.com/privacy-legal.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        </li>
      </ul>
      <div className="copyright">&copy; {year} Ansira</div>
    </StyledFooterNav>
  ) : (
    <StyledFooterNav>
      <ul>
        <li>
          <a
            href="http://www.landroverusa.com/contact-us.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact
          </a>
        </li>
        <li>
          <a
            href="http://www.landroverusa.com/terms-and-conditions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; Conditions
          </a>
        </li>
        <li>
          <a
            href="http://www.landroverusa.com/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        </li>
      </ul>
      <div className="copyright">&copy; {year} Ansira</div>
    </StyledFooterNav>
  );
};

export default FooterNav;
